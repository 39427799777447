export interface Preset {
    id: string;
    title: string; 
    subtitle?: string;
    hour?: string;
    winnersPerImage: number;
    colors: {
        primary: string;
        secondary: string;
        tertiary: string;
    };
    groupByTitle?: boolean;
}

export const DefaultPresets: Preset[] = [
    {
        id: "preset01",
        title: "PIX das 12:30h",
        subtitle: "Ganhadores dos 25 PIX de R$ 100",
        winnersPerImage: 5,
        colors: {
            primary: "#770665",
            secondary: "#770665",
            tertiary: "#FFF5C2"
        }
    },
    {
        id: "preset02",
        title: "PIX das 15:00h",
        subtitle: "Ganhadores dos 25 PIX de R$ 100",
        winnersPerImage: 5,
        colors: {
            primary: "#00440A",
            secondary: "#1F8A0A",
            tertiary: "#EDFE4D"
        }
    },
    {
        id: "preset03",
        title: "PIX das 18:00h",
        subtitle: "Ganhadores dos 25 PIX de R$ 100",
        winnersPerImage: 5,
        colors: {
            primary: "#610043",
            secondary: "#FFFFFF",
            tertiary: "#FFDEDB"
        }
    },
    {
        id: "preset04",
        title: "PIX das 21:00h",
        subtitle: "Ganhadores dos 25 PIX de R$ 100",
        winnersPerImage: 5,
        colors: {
            primary: "#FFFFFF",
            secondary: "#82B9FF",
            tertiary: "#16324C"
        }
    }
];