import { Typography } from "@mui/material";
import { useState } from "react";
import { AutocompleteInput, DateTimeInput, FileInput, ReferenceInput, SimpleForm, TextInput, required, useNotify, useRedirect } from "react-admin";
import { createActionRequest } from "../../../core/Actions";

export const DailyRaffleRegistration = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [fileToUpload, setFileToUpload] = useState(null);

    function useFileContents(file) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = () => {
                const contents = (reader.result as string).trim();
    
                try {
                    resolve(contents);
                } catch(e) {
                    console.error("failed to parse result file: %O", e);
                    reject(e);
                }
            };
    
            reader.readAsText(file);
        });
    }

    return (
        <SimpleForm
            onSubmit={async (values) => {
                try {
                    // Pushes to remote
                    await createActionRequest("post", "dailyRaffles", -1, "addResult", {
                        ...values,
                        results: JSON.parse(await useFileContents(fileToUpload))
                    });

                    // Notifies creation
                    notify("ra.notification.created", {
                        type: "info",
                        messageArgs: { smart_count: 1 },
                    });

                    // Redirects back to the list
                    redirect("list", "dailyRaffles");
                } catch (err) {
                    console.error(err);

                    notify("Ocorreu um erro ao processar a requisição.", {
                        type: "error",
                    });
                }
            }}
        >
            <Typography variant="h5" mb={3}>
                Registrando resultado de sorteio diário
            </Typography>

            <DateTimeInput
                source="datetime"
                defaultValue={new Date()}
                validate={required()}
                autoFocus
            />

            <TextInput 
                source="title"
                helperText="Título para o sorteio desta hora. Por exemplo, 'PIX da Tarde', 'PIX da Noite', etc. Pessoas verão este título."
                validate={required()}
                className="w-full"
            />

            <ReferenceInput
                source="parentRaffleId"
                reference="raffles"
                filter={{
                    kind: "MAIN"
                }}
            >
                <AutocompleteInput 
                    optionText="title"
                    label="Sorteio pai"
                    className="w-full"
                    helperText="CONFIRA ANTES DE ENVIAR. Somente números comprados neste sorteio pai participaram deste sorteio diário."
                    clearIcon={false}
                    validate={required()}
                />
            </ReferenceInput>

            <FileInput
                source="file"
                validate={required()}
                onChange={(file) => setFileToUpload(file)}
                accept={".json"}
            />
        </SimpleForm>
    );
}