import { AutocompleteArrayInput, Datagrid, FilterButton, List, NumberField, Pagination, ReferenceArrayInput, ReferenceField, TextField, TopToolbar } from "react-admin";

const ListPagination = () => <Pagination perPage={10} rowsPerPageOptions={[5, 10, 30, 50, 100]} />;

const RaffleLuckyNumberFilter = [
    <ReferenceArrayInput alwaysOn source="raffleId" reference="raffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="resources.userLuckyNumbers.filters.raffleTitle" />
    </ReferenceArrayInput>
];

const RaffleLuckyNumberActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

export const RaffleLuckyNumberList = () => (
    <List
        filters={RaffleLuckyNumberFilter}
        actions={<RaffleLuckyNumberActions />}
        pagination={<ListPagination/>}
        perPage={10}
    >
        <Datagrid rowClick="show">
            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <NumberField source="number" />
        </Datagrid>
    </List>
);