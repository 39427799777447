import { TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import { Datagrid, DateField, DateTimeInput, List, SelectInput, ShowButton, TextField, TextInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { BrazilianStates } from "../../../../core/BrazilianStates";

const exporter = (records) => {
    if (!records) {
       return;
    }
   
    const txtData = records.map((record) => `
        Nome: ${record.fields.name}
        Telefone: ${record.fields.phone}
        Endereço de e-mail: ${record.fields.email}
        Cidade: ${record.fields.city}   
        Estado: ${record.fields.state}
        Endereço: ${record.fields.address}
        Mensagem / História: ${record.fields.message}`
    ).join("\n\n");

    // same as trim, but only removes leading spaces and tabs (not newlines)
    const trimmedTxtData = txtData.replace(/^[^\S\r\n]+(?![\r\n]{2,})/gm, "");
    
    // Create a blob with the data
    const blob = new Blob([trimmedTxtData], { type: "text/plain;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    // Create a link and click on it
    const link = document.createElement("a");
    link.href = url;
    link.download = "dados.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const CityAndStateFilters = (props) => {
    const [selectedState, setSelectedState] = useState(null);
    const useCtx = useFormContext();

    useEffect(() => {
        setSelectedState(useCtx.getValues("fields.state"));
        useCtx.setValue("fields.city", "");
    }, [selectedState]);

    return (
        <div className="flex w-full gap-2">
            <SelectInput
                label="Pesquisar por estado"
                source="fields.state"
                fullWidth
                alwaysOn
                onChange={event => setSelectedState(event.target.value)}
                choices={BrazilianStates}
            />
            
            <TextInput alwaysOn fullWidth source="fields.city" label="Cidade" disabled={!selectedState} />
        </div>
    );
};

const Filters = [
    <DateTimeInput alwaysOn fullWidth source="createdAt$gte" label="Enviados desde" />,
    <DateTimeInput alwaysOn fullWidth source="createdAt$lte" label="Enviados até" />,
    <TextInput alwaysOn fullWidth source="fields.phone" label="DDD" inputProps={{ maxLength: 2 }} />,
    <CityAndStateFilters alwaysOn fullWidth />
];  

export const ConteSuaHistoriaList = () => (
    <List
        hasCreate={false}
        hasEdit={false}
        hasShow={true}
        exporter={exporter}
        filters={Filters}
        queryOptions={{ refetchInterval: false, refetchIntervalInBackground: false, refetchOnWindowFocus: false }}
        filter={{ formName: "viva-sua-historia" }}
        sort={{ field: "createdAt", order: "DESC" }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="fields.name" />
            <TextField source="fields.state" />
            <TextField source="fields.city" />
            <DateField source="createdAt" showTime />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
            </TableCell>
        </Datagrid>
    </List>
);