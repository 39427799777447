import BallImageURL from "../../../assets/bolinha.png";

/**
 * Represents a ball in the result generator page.
 * @param props Any props to be passed to the ball.
 * @returns 
 */
export const Ball = (props: {
    number: number;
}) => {
    return (
        <div
            className="relative flex items-center justify-center"
            style={{
                color: '#000000',

                fontWeight: 800,
                fontSize: 24,
                fontFamily: 'Tilt Neon',

                width: Ball.width,
                height: Ball.height
            }}
        >
            <img
                className="absolute top-0 left-0 w-full h-full"
                src={BallImageURL}
                crossOrigin="anonymous"
                width={Ball.width}
                height={Ball.height}
                loading="eager"
                decoding="async"
                draggable="false"

                style={{
                    zIndex: -1
                }}
            />

            <div>
                { String(props.number).padStart(2, "0") }
            </div>
        </div>
    );
}

Ball.width = 56;
Ball.height = 56;