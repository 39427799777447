import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import LeakRemove from "@mui/icons-material/LeakRemove";
import Undo from "@mui/icons-material/Undo";
import CloudOff from "@mui/icons-material/CloudOff";
import { createActionRequest } from "../../core/Actions";
import { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";

type GlobalCommandActions = "nodeForcefulShutdown" | "nodeCloseDbConnections" | "nodeRollbackTransactions";

async function callSimpleCommand(command: GlobalCommandActions): Promise<{ acks: number, errors: number }> {   
    // Pushes to remote
    return (await createActionRequest("post", "applications", -1, command)).json();
}

function simpleActionButton(builder: {
    command: GlobalCommandActions,
    label: string,
    icon: React.ReactNode
}) {
    // A state for the disabled state of the button
    const [disabled, setDisabled] = useState(false);
    const notify = useNotify();

    async function callCommand() {
        setDisabled(true);
        try {
            const result = await callSimpleCommand(builder.command);

            // Displays a toast notification
            notify(`${result.acks} receberam; ${result.errors} falharam`, {
                type: result.errors > 0 ? "warning" : "success",
                messageArgs: { smart_count: 1 },
            });

        } finally {
            setDisabled(false);
        }
    }

    return (
        <Button
            startIcon={builder.icon}
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={() => callCommand()}
        >{builder.label}</Button>
    );
}

const AllLogLevels = ["silly", "debug", "verbose", "log", "info", "warn", "error"] as const;
type TLoggerLevel = typeof AllLogLevels[number];

export const AppControlPage = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [localLoggerLevel, setLocalLoggerLevel] = useState("none");

    async function setLoggerLevel(level: TLoggerLevel | "none") {
        await createActionRequest("post", "applications", -1, "nodeSetLoggerLevel", { 
            level: (level === "none") ? null : level
        });

        notify("Nível de log alterado.", { type: "success" });

        setLocalLoggerLevel(level);
    }

    async function getLoggerLevel() {
        return (await dataProvider.getOne("applications", { id: -1 })).data.loggerGlobalLevel;
    }

    useEffect(() => {
        const fetchData = async () => {
            setLocalLoggerLevel(await getLoggerLevel() ?? "none");
        };

        fetchData();
    }, []);

    return (
        <div className="py-5">
            <div className="space-y-6">
                <Typography variant="h4">Controles da aplicação</Typography>

                <div className="space-y-3">
                    <Typography variant="h6">Ações rápidas</Typography>

                    <div className="grid grid-cols-1 gap-6 md:grid-cols-5">
                        {
                            simpleActionButton({
                                command: "nodeForcefulShutdown",
                                icon: <CloudOff />,
                                label: "Encerrar instâncias forçadamente"
                            })
                        }
                        {
                            simpleActionButton({
                                command: "nodeCloseDbConnections",
                                icon: <LeakRemove />,
                                label: "Encerrar conexões DB forçadamente"
                            })
                        }
                        {
                            simpleActionButton({
                                command: "nodeRollbackTransactions",
                                icon: <Undo />,
                                label: "Forçar rollback em transactions abertas"
                            })
                        }
                    </div>
                </div>

                <div className="mt-5 space-y-3">
                    <Typography variant="h6">Definições</Typography>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Log level</InputLabel>
                        <Select
                            label="Age"
                            value={localLoggerLevel}
                            onChange={(e) => setLoggerLevel(e.target.value as TLoggerLevel)}
                        >
                            <MenuItem value={"none"} key={"default"}>
                                Padrão da aplicação
                            </MenuItem>

                            {
                                AllLogLevels.map(level => (
                                    <MenuItem value={level} key={level}>{level}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};