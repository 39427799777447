import { Typography } from "@mui/material";
import { SimpleForm, TextInput, Create, BooleanInput, ImageInput, ImageField, SelectInput } from "react-admin";
import { ResellerMaterialCategory } from "../../Affiliates";

export const ResellersMaterialCreate = () => (
    <Create>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Criar material do revendedor</Typography>

            <TextInput fullWidth source="title" />

            <ImageInput
                fullWidth
                accept="image/png, image/jpg, image/jpeg"
                source="url"
            >
                <ImageField fullWidth source="src" title="title" />
            </ImageInput>

            <SelectInput choices={ResellerMaterialCategory} source="category" />
            
            <BooleanInput source="active" />
        </SimpleForm>
    </Create>
);