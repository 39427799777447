import { Create, NumberInput, required, SimpleForm, TextInput } from "react-admin";
import { Typography } from "@mui/material";

export const MatrixCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <Typography variant="h5" mb={3}>Criar matriz</Typography>

                <TextInput fullWidth source="name" validate={[required()]} />

                <NumberInput fullWidth source="size" validate={[required()]} />

                <NumberInput fullWidth source="numbersPerBond" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}