/* @TODO: Use these enums from @luckymaker/shared. Currently, they are copy-pasted due to compiler not supporting TS 4.9+. */

export enum BrazilianState {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",

    /** Distrito Federal is not a state, but Luckymaker considers it a state. */
    DF = "DF",
}

export enum BrazilianRegion {
    NORTE = "NORTE",
    NORDESTE = "NORDESTE",
    CENTRO_OESTE = "CENTRO_OESTE",
    SUDESTE = "SUDESTE",
    SUL = "SUL"
}

interface IBrazilianRegionInfo {
    name: string;
}

export const BrazilianRegionInfo = {
    [BrazilianRegion.CENTRO_OESTE]: { name: "Centro-Oeste" },
    [BrazilianRegion.NORDESTE]: { name: "Nordeste" },
    [BrazilianRegion.NORTE]: { name: "Norte" },
    [BrazilianRegion.SUDESTE]: { name: "Sudeste" },
    [BrazilianRegion.SUL]: { name: "Sul" }
} as Record<BrazilianRegion, IBrazilianRegionInfo>;

interface IBrazilianStateInfo {
    region: BrazilianRegion
}

export const BrazilianStateInfo = {
    [BrazilianState.AC]: { region: BrazilianRegion.NORTE },
    [BrazilianState.AP]: { region: BrazilianRegion.NORTE },
    [BrazilianState.AM]: { region: BrazilianRegion.NORTE },
    [BrazilianState.PA]: { region: BrazilianRegion.NORTE },
    [BrazilianState.RO]: { region: BrazilianRegion.NORTE },
    [BrazilianState.RR]: { region: BrazilianRegion.NORTE },
    [BrazilianState.TO]: { region: BrazilianRegion.NORTE },

    [BrazilianState.AL]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.BA]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.CE]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.MA]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.PB]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.PE]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.PI]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.RN]: { region: BrazilianRegion.NORDESTE },
    [BrazilianState.SE]: { region: BrazilianRegion.NORDESTE },

    [BrazilianState.GO]: { region: BrazilianRegion.CENTRO_OESTE },
    [BrazilianState.MT]: { region: BrazilianRegion.CENTRO_OESTE },
    [BrazilianState.MS]: { region: BrazilianRegion.CENTRO_OESTE },
    [BrazilianState.DF]: { region: BrazilianRegion.CENTRO_OESTE },

    [BrazilianState.ES]: { region: BrazilianRegion.SUDESTE },
    [BrazilianState.MG]: { region: BrazilianRegion.SUDESTE },
    [BrazilianState.RJ]: { region: BrazilianRegion.SUDESTE },
    [BrazilianState.SP]: { region: BrazilianRegion.SUDESTE },

    [BrazilianState.PR]: { region: BrazilianRegion.SUL },
    [BrazilianState.RS]: { region: BrazilianRegion.SUL },
    [BrazilianState.SC]: { region: BrazilianRegion.SUL }
} as Record<BrazilianState, IBrazilianStateInfo>;

export const BrazilianRegions = Object.entries(BrazilianRegionInfo).map(
    ([key, info]) => ({ id: key, name: `${info.name} (${ Object.entries(BrazilianStateInfo).filter(([k, v]) => v.region === key).map(([k]) => k).join(", ") })` })
);