import Group from "@mui/icons-material/Group";
import ConfirmationNumber from "@mui/icons-material/ConfirmationNumber";
import { UserList } from "./users/UsersList";
import { UserEdit } from "./users/UserEdit";
import { UserLuckyNumberList } from "./users/lucky-numbers/UserLuckyNumberList";
import { UserluckynumberShow } from "./users/lucky-numbers/UserLuckyNumberShow";
import { UserCreate } from "./users/UserCreate";
import { UserShow } from "./users/UserShow";

export const UsersResource = {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    show: UserShow,
    icon: Group
}

export const UserLuckyNumbersResource = {
    list: UserLuckyNumberList,
    show: UserluckynumberShow,
    icon: ConfirmationNumber
}