import { SelectInput, AutocompleteArrayInput, Datagrid, List, ReferenceField, ReferenceInput, SelectField, TextField, FunctionField, TextInput } from "react-admin";
import { AffiliateActivityTypes, AffiliateWithdrawStatus } from "../../Affiliates";

const AffiliateActivityListFilters = () => (
    [
        <TextInput type="number" alwaysOn source="affiliateId" label="ID do revendedor" />,

        <ReferenceInput alwaysOn key="affiliateName" source="affiliateId" reference="affiliates">
            <AutocompleteArrayInput optionText="name" fullWidth source="name,cpf,slug" label="Nome, CPF ou identificador do revendedor" />
        </ReferenceInput>,

        <SelectInput alwaysOn fullWidth label="Atividade" source="type" choices={AffiliateActivityTypes} emptyText="Qualquer atividade" />,

        <SelectInput alwaysOn fullWidth label="Status" source="data.status" choices={AffiliateWithdrawStatus} emptyText="Qualquer status" />
    ]
);

export const AffiliateActivityList = () => (
    <List
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
        filters={AffiliateActivityListFilters()}
    >
        <Datagrid rowClick="show">
            <ReferenceField label="Revendedor" reference="affiliates" source="affiliateId">
                <TextField label="Revendedor" source="name" />
            </ReferenceField>

            <SelectField label="Atividade" source="type" choices={AffiliateActivityTypes} />

            <FunctionField
                source="type"
                label="Informação"
                render={(record) => {
                    if (record.type === "WITHDRAW") {
                        return <SelectField label="Status" source="data.status" choices={AffiliateWithdrawStatus} />;
                    }

                    if (record.type === "SLUG_CHANGED") {
                        return <span>
                            De <strong className="line-through">{ record.data.oldSlug }</strong> para <strong>{ record.data.newSlug }</strong>
                        </span>
                    }
                }}
            />                       
        </Datagrid>
    </List>
);