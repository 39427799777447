import { Popover, Typography, Button } from "@mui/material";
import React from "react";
import { ArrayField, ChipField, CreateButton, Datagrid, DateField, FunctionField, Link, List, SingleFieldList, TopToolbar, useRecordContext } from "react-admin";

export const TotalContemplations = () => {
    const record = useRecordContext();

    const totalContemplations = record.raffles.reduce((accumulator, current) => {
        return accumulator += current.prizes.length;
    }, 0);

    return (
        <FunctionField
            label="Total de contemplações"
            render={() => `${totalContemplations} contemplações`}
        />
    )
}

export const DailyRaffleList = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <List
            actions={
                <TopToolbar>
                    <div>
                        <Button aria-describedby={id} variant="text" onClick={handleClick}>
                            Ações
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="flex flex-col [&>*]:!p-3">
                                <CreateButton label="Gerar com Quina" />
                                <CreateButton label="Inserir com arquivo" to={"register"} />
                            </div>
                        </Popover>
                    </div>
                </TopToolbar>
            }
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <DateField source="date" />
    
                <ArrayField source="raffles" label="Horários dos sorteios">
                    <SingleFieldList>
                        <ChipField
                            className="inline-block ml-3 !pointer-events-none"
                            source="time"
                        ></ChipField>
                    </SingleFieldList>
                </ArrayField>
                
                <TotalContemplations />
            </Datagrid>
        </List>
    );
}