import { Typography } from "@mui/material";
import { Edit, SimpleForm, TextInput, required, email, PasswordInput, regex, number, maxLength, minLength, SelectField, SelectInput } from "react-admin";
import { BrazilianStates } from "../../../core/BrazilianStates";
import { AffiliateRegistrationStatus } from "../Affiliates";
import { ResellerRoles } from "../../../core/ResellerRoles";

export const AffiliateEdit = () => (
    <Edit>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Editar revendedor</Typography>

            <div className="flex-1">
                <SelectInput choices={ ResellerRoles } fullWidth label="Cargo" source="role" validate={[required()]} />
            </div>

            <TextInput fullWidth source="name" />
            <TextInput fullWidth
                label="CPF/CNPJ"
                source="document"
                inputProps={{ maxLength: 14 }}
                parse={(value) => value.replace(/[^0-9]/g, "")}
                validate={[required(), regex(/([0-9\.\-]+)/)]}
                helperText="Não é necessário inserir hífen ou pontos"
            />

            <TextInput fullWidth source="email" type="email" validate={[required(), email()]} />
            <TextInput fullWidth label="Telefone" source="tel" validate={[required(), number("Deve conter somente números"), maxLength(11), minLength(9)]} />

            <TextInput
                fullWidth
                label="Identificador"
                source="slug"
                validate={required()}
                helperText="Por exemplo, 'bancajose', 'joao-silva123'."
            />

            <PasswordInput fullWidth source="password" />

            <TextInput
                fullWidth
                label="CEP"
                source="cep"
                parse={(value) => value.replace(/[^0-9]/g, "")}
                validate={[required(), regex(/[0-9][0-9]\.?[0-9][0-9][0-9]\-?[0-9][0-9][0-9]/, "O CEP inserido é inváldo.")]}
                helperText="Não é necessário inserir hífen ou pontos"
            />

            <SelectInput validate={[required()]} source="registrationStatus" choices={AffiliateRegistrationStatus} />

            <div className="flex w-full">
                <div className="w-2/3 pr-3">
                    <TextInput fullWidth label="Endereço completo" source="address" validate={required()} />
                </div>

                <div className="flex-1">
                    <TextInput fullWidth label="Bairro" source="neighborhood" validate={required()} />
                </div>
            </div>

            <div className="flex w-full">
                <div className="w-2/3 pr-3">
                    <TextInput fullWidth label="Cidade" source="city" validate={required()} />
                </div>

                <div className="flex-1">
                    <SelectInput choices={ BrazilianStates } fullWidth label="Estado (UF)" source="state" validate={[required(), minLength(2), maxLength(2)]} />
                </div>
            </div>

            <TextInput fullWidth label="Chave PIX" source="data.PIXKey" helperText="A chave pix deve estar no mesmo nome do titular da conta." />
        </SimpleForm>
    </Edit>
);