import { Typography } from "@mui/material";
import { ReferenceInput, SelectInput, SimpleForm, TextInput, required, Create, NumberInput, useDataProvider, useNotify } from "react-admin";
import { createDataProviderRequest } from "../../../../core/Actions";

export const FederalLottoRafflePrizeCreate = () => {
    const notify = useNotify();

    const onSubmit = async (prize: {
        title: string;
        raffleId: number;
        amount?: number;
    }) => {
        try {
            await createDataProviderRequest("post", "federalLottoRafflePrizes/many", Array(prize.amount).fill(0).map(() => {
                const data = { ...prize };

                delete data.amount;

                return data;
            }));
        } catch(e) {
            notify("Ocorreu um erro ao criar os prêmios.", { type: "error" });
            throw e;
        }

        notify("Prêmios criados com sucesso!", { type: "success" });
    };

    return (
        <Create>
            <SimpleForm onSubmit={(data) => onSubmit(data as any)}>
                <Typography variant="h5" mb={3}>Criar prêmio</Typography>

                <TextInput fullWidth source="title" validate={[required()]} />

                <ReferenceInput fullWidth source="raffleId" reference="federalLottoRaffles" validate={[required()]}>
                    <SelectInput fullWidth optionText="title" />
                </ReferenceInput>

                <NumberInput fullWidth min={1} source="amount" label="Quantidade" validate={[required()]} />
            </SimpleForm>
        </Create>
    )
};