import Handshake from "@mui/icons-material/Handshake";
import Book from "@mui/icons-material/Book";

import { AffiliateActivityList } from "./affiliates/activity/AffiliateActivityList";
import { AffiliateActivityShow } from "./affiliates/activity/AffiliateActivityShow";
import { AffiliateCreate } from "./affiliates/AffiliateCreate";
import { AffiliateEdit } from "./affiliates/AffiliateEdit";
import { AffiliateList } from "./affiliates/AffiliateList";
import { AffiliateShow } from "./affiliates/AffiliateShow";
import { ResellersMaterialList } from "./affiliates/materials/ResellersMaterialList";
import { ResellersMaterialCreate } from "./affiliates/materials/ResellersMaterialCreate";

export const AffiliatesResource = {
    list: AffiliateList,
    edit: AffiliateEdit,
    create: AffiliateCreate,
    show: AffiliateShow,
    icon: Handshake,
};

export const AffiliateActivityResource = {
    list: AffiliateActivityList,
    show: AffiliateActivityShow,
    icon: Handshake,
};

export const ResellersMaterialResource = {
    create: ResellersMaterialCreate,
    list: ResellersMaterialList,
    icon: Book,
};

export const ResellerMaterialCategory = [
    { id: "POST", name: "Post" },
    { id: "STORY", name: "Story" },
    { id: "PRINT", name: "Impressão" }
];

export const AffiliateActivityTypes = [
    { id: "WITHDRAW", name: "Saque" },
    { id: "LOG", name: "Log" },
    { id: "SLUG_CHANGED", name: "Troca de identificador" }
];

export const AffiliateWithdrawStatus = [
    { id: "PENDING", name: "Pendente" },
    { id: "APPROVED", name: "Aprovado" },
    { id: "REFUSED", name: "Recusado" }
];

export const AffiliateRegistrationStatus = [
    { id: "PENDING", name: "Pendente" },
    { id: "APPROVED", name: "Aprovado" },
    { id: "BLOCKED", name: "Bloqueado" },
    { id: "REFUSED", name: "Recusado" }
];