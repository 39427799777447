import { Resource, ResourceProps } from "react-admin";
import get from "lodash.get";

export const hasAccess = (permissions, ...askedPermissions: string[]) =>
    askedPermissions.every(permission => get(permissions, permission, false));

interface Props extends ResourceProps {
    permissions: any;
}

export const ResourceWithPermissions = (properties: Props) => {
    const {
        permissions,
        name,
        list,
        create,
        edit,
        show,
        ...props
    } = properties;

    const access = {
        enabled: hasAccess(permissions, `${name}.enabled`),
        list: hasAccess(permissions, `${name}.list`),
        create: hasAccess(permissions, `${name}.create`),
        edit: hasAccess(permissions, `${name}.edit`),
        show: hasAccess(permissions, `${name}.show`),
    };

    if (access.enabled === false) {
        return undefined;
    }

    return (
        <Resource
            {...props}
            name={name}

            list={access.list !== false ? list : undefined}
            create={access.create !== false ? create : undefined}
            edit={access.edit !== false ? edit : undefined}
            show={access.show !== false ? show : undefined}
        />
    );
};