import { Button, useNotify, useRecordContext, useUpdate } from "react-admin";

export const ReapprovePaymentButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate("payments");
    const notify = useNotify();

    const doApprove = async () => {
        await update("payments", {
            id: record?.id,
            data: {
                status: "PENDING"
            }
        });

        await update("payments", {
            id: record?.id,
            data: {
                status: "APPROVED"
            }
        });

        notify(`O pagamento #${record.id} foi reaprovado.`, { type: "success" });
    };

    return (
        <Button
            color={"secondary" as any}
            label={"Reaprovar #" + record?.id}
            onClick={doApprove}
        />
    )
};