import { Input, InputLabel } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BooleanField, Button, CloneButton, EditButton, ImageField, ReferenceField, SelectField, Edit as Show, Tab, TabbedShowLayout, TextField, TopToolbar, useRecordContext } from "react-admin";
import { createDataProviderRequest } from "../../../../core/Actions";
import { RafflePrizeKind } from "../../Raffles";

const AddNumbers = () => {
    const record = useRecordContext();
    const [inputNumbers, setInputNumbers] = useState("");
    const [numbers, setNumbers] = useState<{ id: number; number: string}[]>([]);

    // Fetch numbers from the API
    interface Draw {
        number: string;
        id: number;
    }

    const fetchNumbers = useCallback(async () => {
        if (!record.id) {
            return;
        }

        const response = await createDataProviderRequest("get", "raffleMatrixDraws", null, {
            query: {
                filter: {
                    prizeId: record.id
                },

                attributes: ["number", "id"]
            }
        });

        const data: Draw[] = await response.json();

        // Use Map to ensure unique numbers
        const numberMap = new Map<string, Draw>();

        // Map numbers to ensure uniqueness
        for (const draw of data) {
            numberMap.set(draw.number, draw);
        }

        // Sort numbers by id
        const uniqueNumbers = Array.from(numberMap.values()).sort((a, b) => a.id - b.id);

        setNumbers(uniqueNumbers);
    }, [record.id]);

    // Send a number to the API
    const sendNumber = useCallback(async (num: string, fetch = true) => {
        if (!record.id) {
            return;
        }

        await createDataProviderRequest("post", "raffleMatrixDraws", {
            number: num,
            prizeId: record.id
        });

        if (fetch) {
            // Fetch the updated numbers after adding a new number
            fetchNumbers();
        }
    }, [record.id, fetchNumbers]);

    // Delete a number from the API
    const deleteNumber = useCallback(async (numId: number, number: string) => {
        if (!record.id) {
            return;
        }

        if (!confirm(`Tem certeza que deseja excluir a dezena ${String(number).padStart(2, "0")}?`)) {
            return;
        }

        await createDataProviderRequest("delete", `raffleMatrixDraws/${numId}`, null);

        // Fetch the updated numbers after deleting a number
        fetchNumbers();
    }, [record.id, fetchNumbers]);

    // Delete all numbers from the API
    const deleteAllNumbers = useCallback(async () => {
        if (!record.id) {
            return;
        }

        if (confirm("Tem certeza que deseja excluir todas as dezenas?")) {
            const response = await createDataProviderRequest("get", "raffleMatrixDraws", null, {
                query: {
                    prizeId: record.id
                }
            });

            const data = await response.json();

            // Delete each number individually
            await Promise.all(
                data.map((draw: { id: number }) =>
                    createDataProviderRequest("delete", `raffleMatrixDraws/${draw.id}`, null)
                )
            );

            setNumbers([]);
        }
    }, [record.id]);

    function insertNumbers() {
        // Sanitize input and split into chunks of 2 digits
        const sanitizedNumbers = inputNumbers.replace(/[^0-9]/g, "");

        // Split into chunks of 2 digits
        const arrayNumbers = sanitizedNumbers.match(/.{1,2}/g) || [];

        // Validate and filter numbers that are between 1 and 60
        const validNumbers = arrayNumbers.filter((num) => num.length === 2 && !isNaN(Number(num)) && Number(num) > 0 && Number(num) <= 60);

        // If there are no valid numbers, return
        if (validNumbers.length === 0) {
            return;
        }

        // Filter out numbers that are already in the list
        const uniqueNewNumbers = validNumbers.filter((number) => !numbers.some((n) => n.number === number));

        setNumbers((prevNumbers) => {
            // Create a copy of the previous numbers
            const updatedNumbers = [...prevNumbers];

            // Send each number sequentially to respect the order
            const chain = uniqueNewNumbers.reduce((promise, number) => {
                // Send the number and wait for the promise to resolve (without fetching numbers)
                return promise.then(() => sendNumber(number, false));
            }, Promise.resolve());

            // Fetch the updated numbers after adding all new numbers
            chain.then(fetchNumbers);

            return updatedNumbers;
        });

        // Clear input after adding numbers
        setInputNumbers("");

        // Focus input after adding numbers
        const input = document.querySelector("input") as HTMLInputElement;
        input.focus();
    }

    // Handle Enter key to insert numbers
    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault();
            insertNumbers();
        }
    }

    // Fetch numbers on component mount
    useEffect(() => {
        fetchNumbers();
    }, []);

    return (
        <div>
            <InputLabel>Dezenas lançadas (em ordem de saída)</InputLabel>

            <Input
                fullWidth
                value={inputNumbers}
                onKeyDown={handleKeyDown}
                onChange={(e) => setInputNumbers(e.target.value)}
            />

            <div className="flex justify-between w-full">
                <Button
                    label="Inserir dezenas"
                    onClick={insertNumbers}
                />
                <Button
                    label="Limpar todas as dezenas"
                    className="!text-danger"
                    onClick={deleteAllNumbers}
                />
            </div>

            {
                numbers.length > 0 && (
                    <div className="flex flex-wrap gap-2 my-5">
                        {numbers.map(({ id, number }, index) => (
                            <div
                                key={index}
                                onClick={() => deleteNumber(id, number)}
                                className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer select-none bg-slate-200 hover:bg-danger hover:text-white"
                            >
                                {String(number).padStart(2, "0")}
                            </div>
                        ))}
                    </div>
                )
            }

            <span className="text-xs text-gray-500">
                Você pode clicar em uma dezena para removê-la da lista.
            </span>
        </div>
    );
}

const PrizeTabs = () => {
    const record = useRecordContext();

    return (
        <TabbedShowLayout>
            <Tab label="Informações do prêmio">
                <TextField source="name" />
                <TextField source="siteName" />
                <ImageField emptyText="Sem imagem" label="Imagem em destaque (aspecto 16:9)" source="thumbnail.src" />
                <SelectField source="raffleKind" choices={RafflePrizeKind} />
                <BooleanField source="unlisted" />

                <ReferenceField source="raffleId" reference="raffles">
                    <TextField source="title" />
                </ReferenceField>
            </Tab>

            { record.raffleKind === "MATRIXES" &&
                <Tab label="Dezenas lançadas">
                    <AddNumbers />
                </Tab>
            }
        </TabbedShowLayout>
    );
}

export const RafflePrizeShow = () => {
    return (
        <Show
            actions={
                <TopToolbar>
                    <CloneButton />
                    <EditButton />
                </TopToolbar>
            }
        >
            <PrizeTabs />
        </Show>
    );
}
