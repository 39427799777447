import { Menu, MenuItem, Typography } from "@mui/material";
import { Configuration } from "../../../config";
import { useState } from "react";
import { Button, DateField, DeleteButton, EditButton, NumberField, Show, Tab, TabbedShowLayout, TextField, TopToolbar, useNotify, useShowContext } from "react-admin";

import { RaffleDraw } from "./RaffleDraw";
import { FederalLottoPrizesGrid } from "./parts/FederalLottoPrizesGrid";

const FederalLottoRaffleShowActions = () => {
    const { record } = useShowContext();

    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

    /**
     * Performs an export action.
     * @param type The export type.
     */
    function performExport(type: "Display") {
        const actionUrl = new URL(Configuration.baseUrl + "/federalLottoRaffles/" + record.id + "/actions/exportTo" + type);
        actionUrl.searchParams.set("access_token", localStorage.getItem("admin-token") as string);

        window.open(actionUrl.toString());
    }

    const [isDrawing, setIsDrawing] = useState(false);

    const displayURL = new URL(window.location.href);

    // Clear the hash
    displayURL.hash = "";

    // If in dev mode
    if (Configuration.isDev) {
        // Point to the local display
        displayURL.host = "localhost:8070";

        // Set the token
        displayURL.searchParams.set("access_token", localStorage.getItem("admin-token") as string);
    } else {
        // Point to the remote display
        displayURL.pathname = "/sorteador";
    }

    // Set the raffleId param to the current raffle ID
    displayURL.searchParams.set("federal_lotto_raffle_id", String(record?.id));

    return (
        <TopToolbar>
            <>
                <DeleteButton />

                <Button
                    label="Exportar como"
                    aria-haspopup="true"
                    onClick={(e) => setExportAnchorEl(e.target as any)}
                />

                <Button
                    label="Ações"
                    aria-haspopup="true"
                    onClick={(e) => setActionsAnchorEl(e.target as any)}
                />

                <Menu
                    id="actions-menu"
                    anchorEl={actionsAnchorEl}
                    keepMounted
                    open={Boolean(actionsAnchorEl)}
                    onClose={() => setActionsAnchorEl(null)}
                >
                    {
                        isDrawing && <RaffleDraw open={isDrawing} setOpen={setIsDrawing} />
                    }

                    <MenuItem
                        onClick={() => setIsDrawing(true)}
                    >
                        <i className="mr-1 fa fa-fw fa-shuffle"></i> Realizar sorteio
                    </MenuItem>

                    <a
                        href={displayURL.toString()}
                        target="_blank"

                        onClick={() => setActionsAnchorEl(null)}
                    >
                        <MenuItem>
                            <i className="mr-1 fa fa-fw fa-arrow-up-right-from-square"></i> Abrir no display
                        </MenuItem>
                    </a>
                </Menu>

                <Menu
                    id="export-menu"
                    anchorEl={exportAnchorEl}
                    keepMounted
                    open={Boolean(exportAnchorEl)}
                    onClose={() => setExportAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("Display");
                        }}
                    >
                        Arquivo do Display
                    </MenuItem>
                </Menu>

                <EditButton/>
            </>
        </TopToolbar>
    );
};

export const FederalLottoRaffleShow = () => (
    <Show
        actions={<FederalLottoRaffleShowActions/>}
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <TabbedShowLayout>
            <Tab label="Informações">
                <NumberField source="id" />

                <TextField source="title" />

                <DateField source="createdAt" showTime />

                <DateField source="drawnAt" showTime />
            </Tab>

            <Tab label="Dados técnicos">
                <Typography variant="h6">Loteria Federal</Typography>
                <NumberField source="data.federalLotto.contestNumber" />
                <NumberField source="data.federalLotto.numbers" />

                <Typography variant="h6" className="!mt-3">Quina</Typography>
                <NumberField source="data.quina.contestNumber" />
                <NumberField source="data.quina.numbers" />
            </Tab>

            <Tab label="Prêmios">
                <FederalLottoPrizesGrid />
            </Tab>
        </TabbedShowLayout>
    </Show>
);