import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { CouponCreate } from "./coupons/CouponCreate";
import { CouponList } from "./coupons/CouponList";
import { CouponShow } from './coupons/CouponShow';

export const CouponsResource = {
    list: CouponList,
    create: CouponCreate,
    show: CouponShow,
    icon: ConfirmationNumberIcon
}

export const CouponBenefitTypes = [
    { id: "CREDITS", name: "Créditos" }
];

export const MAX_ADMIN_COUPON_VALUE = 10000;
export const MAX_SAC_COUPON_VALUE = 15;
