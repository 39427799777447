import { TextField, Show, SimpleShowLayout, ReferenceField, NumberField } from "react-admin";

export const FederalLottoRafflePrizeShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField fullWidth source="title" />

            <ReferenceField
                fullWidth
                source="raffleId"
                reference="federalLottoRaffles"
                link="show"
            >
                <TextField source="title" />
            </ReferenceField>

            <ReferenceField
                fullWidth
                label="Número vencedor"
                source="winningNumberId"
                reference="userLuckyNumbers"
                link="show"
            >
                <NumberField source="number" />
            </ReferenceField>

            <TextField source="data.supportNumber" />
            <TextField source="data.calculatedNumber" />
        </SimpleShowLayout>
    </Show>
);