import Download from "@mui/icons-material/Download";
import { useMemo } from "react";
import { Button } from "react-admin";

import { domToPng } from "modern-screenshot";
import { IntermediateRaffleData, IntermediateRafflePrize } from "../ResultGeneratorPage";
import { ResultGeneratorProps } from "./ResultGenerator";
import { ResultGeneratorImage } from "./ResultGeneratorImage";

export interface IntermediateRaffleResultPartProps extends Omit<IntermediateRaffleData, "id" | "name"> {
    pageNumber: number;
    prize: IntermediateRafflePrize;
}

/**
 * Downloads a given image page.
 * @param page The image page.
 */
async function downloadImage(page: number) {
    const pageEl = document.querySelector(".single-page .page-" + (page - 1));

    const cloned = pageEl!.cloneNode(true) as HTMLElement;

    cloned.style.setProperty("width", "1080px");
    cloned.style.setProperty("height", "1080px");

    document.body.appendChild(cloned);

    const dataUrl = await domToPng(cloned);

    const link = document.createElement("a");
    link.download = `resultados-${page}.png`;
    link.href = dataUrl;

    link.click();

    document.body.removeChild(cloned);
}

export type ResultGeneratorBlockProps = Omit<ResultGeneratorProps, "result"> & IntermediateRaffleResultPartProps;

export const ResultGeneratorPart = (props: ResultGeneratorBlockProps) => {
    const pageIndex = useMemo(() => props.pageNumber - 1, [props.pageNumber]);

    return (
        <div className="w-full mx-auto lg:w-1/4 single-page">
            <div className={"page-" + pageIndex}>
                <ResultGeneratorImage {...props} />
            </div>

            <div className="flex items-center justify-center my-3">
                <Button
                    label="Baixar"
                    startIcon={<Download />}
                    size="medium"

                    onClick={() => downloadImage(props.pageNumber)}
                />
            </div>
        </div>
    );
};