import { CreateButton, Datagrid, DateField, DateTimeInput, downloadCSV, EmailField, ExportButton, FunctionField, List, ListActionsProps, NumberField, SearchInput, SelectField, SelectInput, TextField, TextInput, TopToolbar, useListContext, useResourceContext } from "react-admin";
import { AffiliateRegistrationStatus } from "../Affiliates";
import { unparse as convertToRawCSV } from "papaparse";
import { ResellerRoles } from "../../../core/ResellerRoles";

const exporter = records => {
    const recordsToExport = records.map(post => {
        // Use this mapping function to add information for exportation.

        post.tel = `55${post.tel}`;
        post.state ||= "INDEFINIDO";

        return post;
    });

    const rawCSV = convertToRawCSV({
        data: recordsToExport,
        fields: [
            "id",
            "name",
            "slug",
            "data.PIXKey",
            "email",
            "city",
            "state",
            "birthdate",
            "tel",
            "createdAt"
        ],
    });

    downloadCSV(
        rawCSV, 
        // This will be used as the downloaded file name.
        "affiliates"
    ); 
};

const AffiliateFilters = [
    <SearchInput alwaysOn fullWidth source="q" placeholder="Nome, documento ou e-mail" />,
    <TextInput alwaysOn fullWidth source="slug" label="Identificador" />,
    <DateTimeInput alwaysOn fullWidth source="createdAt$gte" label="Cadastros desde" />,
    <DateTimeInput alwaysOn fullWidth source="createdAt$lte" label="Cadastros até" />,
    <SelectInput choices={ ResellerRoles } alwaysOn fullWidth source="role" label="Cargo" emptyText={"Qualquer"} />
];

export const AffiliateListActions = () =>
    <>
        <TopToolbar>
            <CreateButton></CreateButton>
            <ExportButton 
                maxResults={Number.MAX_SAFE_INTEGER}
            ></ExportButton>
        </TopToolbar>
    </>;

export const AffiliateList = () => (
    <List
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
        filters={AffiliateFilters}
        actions={<AffiliateListActions/>}
        exporter={exporter}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="tel" />
            <TextField source="slug" />
            <SelectField source="role" choices={ResellerRoles} />
            <DateField source="createdAt" label="Data do cadastro" showTime={true} showDate={true} />
            <SelectField source="registrationStatus" choices={AffiliateRegistrationStatus} />
        </Datagrid>
    </List>
);
