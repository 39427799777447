import { Typography } from "@mui/material";
import { SimpleForm, Edit, ImageInput, ImageField, TextInput } from "react-admin";

export const RaffleResultEdit = () => (
    <Edit>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Editar resultado</Typography>

            <TextInput fullWidth label="Nome do ganhador" source="winnerName" />

            <ImageInput
                fullWidth
                accept="image/png, image/jpg, image/jpeg"
                source="winnerPhotoUrl"
                label="Foto do ganhador"
            >
                <ImageField fullWidth source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);