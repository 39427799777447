import { Typography } from "@mui/material";
import { Button } from "react-admin";
import { createDataProviderRequest } from "../../../../core/Actions";
import { useState } from "react";

export const MercadoPagoMarketplaceConnector = () => {
    const [disabled, setDisabled] = useState(false);

    const requestMPMarketplaceRenew = async () => {
        setDisabled(true);

        const request = await createDataProviderRequest("post", "/gateways/mercadopago/oauth").then((r) => r.json());

        window.open(request.url, "mpCallbackWindow");

        setDisabled(false);
    };

    return (
        <div className="w-full mt-10">
            <div className="mb-3">
                <Typography variant="h4">Conector do Marketplace</Typography>
                <span>Conecta uma conta em formato marketplace do MercadoPago para realizar split de pagamentos entre sorteios primários e secundários.</span>
            </div>

            <div className="my-3">
                Todos os sorteios cadastrados como <strong>secundários</strong> na plataforma serão automaticamente enviados para a conta secundária citada.
            </div>

            <Button
                onClick={requestMPMarketplaceRenew}
                color="primary"
                label="Renovar conexão"
                type="button"
                size="medium"
                disabled={disabled}
            />
        </div>
    )
};