import { AutocompleteInput, minValue, NumberInput, ReferenceInput, required, TextInput } from "react-admin";

import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Configuration } from "../../../../config";
import { createAuthorizedRequest } from "../../../../core/AuthProvider";

export const RaffleEntropyTab = () => {
    const [disableEntropySettings, setEntropySettingsDisabled] = useState(false);
    const [isDoubleChanceMatrix, _setDoubleChanceMatrix] = useState(false);

    const setDoubleChanceMatrix = (enabled: boolean) => {
        // Changes the state
        _setDoubleChanceMatrix(enabled);

        // Set the number of chances if needed
        form.setValue(
            "settings.entropy.numberOfChances",
            enabled
                ? 2
                : Math.min(1, Number(form.getValues()["settings.entropy.numberOfChances"]) || 1)
        );
    };

    const form = useFormContext();

    const matrixIdWatcher = form.watch(["matrixId"]);

    // Watch for the matrixId + range
    useEffect(() => {
        const matrixId = form.getValues().matrixId;

        if (!matrixId) {
            return;
        }

        // When it changes, we request the range for it
        const url = new URL(`/v1/admin/matrix/${form.getValues().matrixId}/range`, Configuration.baseUrl);
        const req = createAuthorizedRequest(url.toString());

        fetch(req)
            .then((r) => r.json())
            .then((r) => {
                // Suggest numbers to the min and max entropy
                form.setValue("settings.entropy.minNumber", r.min);
                form.setValue("settings.entropy.maxNumber", r.max);
            });
    }, [matrixIdWatcher]);

    // Watch for the matrixId + matrix data
    useEffect(() => {
        const matrixId = form.getValues().matrixId;

        if (!matrixId) {
            // Flag it as external if needed
            setEntropySettingsDisabled(false);
            setDoubleChanceMatrix(false);
            return;
        }

        // When it changes, we request the range for it
        const url = new URL(`/v1/admin/matrix/${matrixId}`, Configuration.baseUrl);
        const req = createAuthorizedRequest(url.toString());

        fetch(req)
            .then((r) => r.json())
            .then((r) => {
                // Flag it as external if needed
                setEntropySettingsDisabled(r.data?.isExternal === true);
                setDoubleChanceMatrix(r.data?.hasDoubleChance === true);
            });
    }, [matrixIdWatcher]);

    return (
        <>
            <ReferenceInput
                source="matrixId"
                reference="matrix"
            >
                <AutocompleteInput
                    className="w-full"
                    optionText="name"
                    label="Matriz"
                    autoSelect
                    clearIcon={false}
                    helperText="Não é necessário selecionar uma matriz. Selecione somente se o sorteio terá cartelas e não somente números da sorte."
                    emptyText="Nenhuma"
                    emptyValue={""}
                />
            </ReferenceInput>

            {
                disableEntropySettings && <div className="p-5 my-5 rounded-md bg-black/5">
                    As configurações de entropia foram desabilitadas pois o sistema não tem controle sobre esta matriz.
                    Ela pode ter sido importada ou gerada externamente.
                </div>
            }

            <NumberInput
                defaultValue="1"
                source="settings.entropy.minNumber"
                validate={[required()]}
                disabled={disableEntropySettings}
                fullWidth
            />

            <NumberInput
                defaultValue="1000000"
                source="settings.entropy.maxNumber"
                validate={[required(), minValue(1)]}
                disabled={disableEntropySettings}
                fullWidth
            />

            <NumberInput
                defaultValue="1"
                source="settings.entropy.numberOfChances"
                validate={[required()]}
                disabled={disableEntropySettings || isDoubleChanceMatrix}
                fullWidth
            />

            <FederalLottoSubForm className="my-5" />
        </>
    );
}

export const FederalLottoSubForm = (props: React.HTMLAttributes<HTMLDivElement>) => {
    const formContext = useFormContext();
    const [editingLoteriaFederal, setEditingLoteriaFederal] = useState(false);

    function startEditingLoteriaFederal() {
        // Updates the last confirmation date
        formContext.setValue("settings.entropy.federalLotto.lastConfirmationDate", new Date().toISOString(), {
            shouldDirty: true
        });

        // Updates the frontend state
        setEditingLoteriaFederal(true);
    }

    return (
        <div className={`w-full p-3 rounded-md shadow-md border-[1px] ${ props.className ?? "" }`}>
            <div className="flex items-center gap-3 mb-3">
                <h1>Configurações para Loteria Federal</h1>

                {
                    !editingLoteriaFederal
                        ? <>
                            <Button onClick={() => startEditingLoteriaFederal()} endIcon={<EditIcon/>}>
                                Reconfirmar
                            </Button>
                        </>
                        : null
                }
            </div>

            <NumberInput disabled={!editingLoteriaFederal} source="settings.entropy.federalLotto.contestNumber" fullWidth />

            <div className="flex flex-col w-full gap-3 md:flex-row">
                {
                    Array(5).fill(0).map((_, index) => (
                        <TextInput key={`federalLotto.number.${index}`} source={`settings.entropy.federalLotto.numbers.${index}`} label={`Número ${ index + 1 }`} disabled={!editingLoteriaFederal} />
                    ))
                }
            </div>
        </div>
    )
}
