import { Button, useNotify, useRecordContext } from "react-admin";
import { createActionRequest } from "../../core/Actions";

export const SendSecondWayButton = () => {
    const showNotification = useNotify();
    const record = useRecordContext();

    return (
        <Button
            label="Enviar segunda via"

            onClick={
                async () => {
                    await createActionRequest("post", "payments", record.paymentId ?? record.id, "sendSecondWay");
                    showNotification("Segunda via enviada com sucesso.", { type: "success" });
                }
            }
        />
    );
}