import { Edit, email, FormTab, PasswordInput, required, TabbedForm, TextInput, DateInput, NumberInput } from "react-admin";

export const UserEdit = () => {
    return (
        <Edit hasShow={true} hasList={true}>
            <TabbedForm>
                <FormTab label="Dados básicos">
                    <TextInput source="email" type="email" validate={[required(), email()]} />

                    <PasswordInput helperText="Deixe em branco para manter como está." source="password" type="password" />

                    <TextInput source="originalName" validate={[required()]} />

                    <TextInput source="socialName" />

                    <TextInput source="cpf" validate={[required()]} />

                    <TextInput source="tel" validate={[required()]} />

                    <DateInput source="birthdate" validate={[required()]} />
                </FormTab>

                <FormTab label="Endereço">
                    <TextInput source="cep" validate={[required()]} />
                    <TextInput source="address" validate={[required()]} />
                    <TextInput source="data.address.number" />
                    <TextInput source="data.address.complement" />
                    <TextInput source="neighborhood" validate={[required()]} />
                    <TextInput source="city" validate={[required()]} />
                    <TextInput source="state" validate={[required()]} />
                </FormTab>

                <FormTab label="Financeiro">
                    <NumberInput source="creditsAmount" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};