import { TableCell } from "@mui/material";
import { AutocompleteArrayInput, CreateButton, Datagrid, DateField, DateInput, EditButton, FilterButton, List, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectField, SelectInput, ShowButton, TextField, TextInput, TopToolbar } from "react-admin";
import { PaymentItemType, PaymentMethods, PaymentStatus } from "../../../core/Payments";

const PaymentFilters = [
    <ReferenceInput alwaysOn key="userCpf" source="userId" reference="users">
        <AutocompleteArrayInput optionText="cpf" fullWidth source="cpf" label="CPF" />
    </ReferenceInput>,

    <ReferenceArrayInput alwaysOn key="userName" source="userId" reference="users">
        <AutocompleteArrayInput optionText="name" fullWidth source="name" label="resources.payments.filters.userName" />
    </ReferenceArrayInput>,

    <ReferenceArrayInput source="raffleId" label="resources.payments.filters.raffleTitle" reference="raffles">
        <SelectArrayInput fullWidth optionText="title" label="resources.payments.filters.raffleTitle" />
    </ReferenceArrayInput>,

    <ReferenceInput alwaysOn key="userEmail" source="userId" reference="users">
        <AutocompleteArrayInput optionText="email" fullWidth source="email" label="Endereço de e-mail" />
    </ReferenceInput>,

    <NumberInput fullWidth source="id" label="ID do pagamento" />,

    <DateInput fullWidth source="createdAt" label="Data do pagamento" />,

    <SelectInput fullWidth source="status" label="Status do pagamento" choices={PaymentStatus} />,
    <SelectInput fullWidth source="method" label="Método de pagamento" choices={PaymentMethods} />,

    <TextInput fullWidth source="uuid" label="Identificador Único (UUID)" />,
];

const PaymentActions = () => (
    <TopToolbar>
        <CreateButton label="Registrar pagamento" />
        <FilterButton/>
    </TopToolbar>
);

export const PaymentList = () => (
    <List
        filters={PaymentFilters}
        actions={<PaymentActions/>}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />

            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>

            <SelectField source="method" choices={PaymentMethods} />
            <SelectField source="status" choices={PaymentStatus} />

            <NumberField source="value" options={{ style: "currency", currency: "BRL" }} />

            <ReferenceField source="affiliateId" sortable={false} reference="affiliates">
                <TextField source="name" />
            </ReferenceField>

            <DateField showTime source="createdAt" />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
                <EditButton />
            </TableCell>
        </Datagrid>
    </List>
);