import Leaderboard from "@mui/icons-material/Leaderboard";
import { DailyRaffleList } from "./daily-raffles/DailyRaffleList";
import { DailyRaffleShow } from "./daily-raffles/DailyRaffleShow";
import { DailyRaffleCreate } from "./daily-raffles/DailyRaffleCreate";

export const DailyRafflesResource = {
    create: DailyRaffleCreate,
    show: DailyRaffleShow,
    list: DailyRaffleList,
    icon: Leaderboard
}