import { InputLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DatagridConfigurable, FormDataConsumer, FormTab, NumberInput, ReferenceField, ReferenceInput, SelectInput, TabbedForm, TextField, TextInput, required, useGetList, useRecordContext, useRedirect } from "react-admin";
import { createDataProviderRequest } from "../../../core/Actions";
import { FederalLottoPrizesGrid } from "./parts/FederalLottoPrizesGrid";

const LottoArrayInput = (props: {
    source: string;
    defaultValue?: number[];
    length?: number;
}) => {
    return <div>
        <InputLabel>Dezenas</InputLabel>

        <div className="grid grid-cols-5 gap-3">        
            {
                Array(props.length ?? 5).fill(0).map((_, index) => {
                    return <NumberInput
                        key={index}
                        defaultValue={props.defaultValue?.[index]}
                        source={props.source + "[" + index + "]"}
                        label={String(index + 1)}
                        validate={[required()]}
                    />;
                })
            }
        </div>
    </div>;
};

export const FederalLottoForm = () => {
    const [lottoResponse, setLottoResponse] = useState<any>(null);

    useEffect(() => {
        createDataProviderRequest("get", "federalLottoRaffles/lotto-data/")
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            setLottoResponse(json);
        });
    }, []);

    return (
        <TabbedForm>
            <FormTab label="Dados básicos">
                <TextInput fullWidth source="title" validate={[required()]} />

                <ReferenceInput reference="raffles" source="parentRaffleId" validate={[required()]}>
                    <SelectInput fullWidth optionText="title" />
                </ReferenceInput>
            </FormTab>

            <FormTab label="Entropia">
                <Typography variant="h6">Loteria Federal</Typography>

                <NumberInput source="data.federalLotto.contestNumber" validate={[required()]} defaultValue={lottoResponse?.federalLotto?.numero} />

                <LottoArrayInput source="data.federalLotto.numbers" defaultValue={lottoResponse?.federalLotto?.listaDezenas} />

                <hr className="my-5" />

                <Typography variant="h6">Quina</Typography>

                <NumberInput source="data.quina.contestNumber" validate={[required()]} defaultValue={lottoResponse?.quina?.numero} />

                <LottoArrayInput source="data.quina.numbers" defaultValue={lottoResponse?.quina?.listaDezenas} />
            </FormTab>

            <FormTab label="Prêmios">
                <FederalLottoPrizesGrid />
            </FormTab>
        </TabbedForm>
    );
};