import { AutocompleteArrayInput, Datagrid, FilterButton, FunctionField, List, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, TextField, TextInput, TopToolbar } from "react-admin";

const UserLuckyNumberFilters = [
    <TextInput alwaysOn 
        type="number"
        source="number"
        inputProps={{
            onInput: 
                // Enforces max length
                (e) => e.currentTarget.value = e.currentTarget.value.slice(0, 7)
        }}
    >
        <AutocompleteArrayInput fullWidth optionText="number" label="resources.userLuckyNumbers.filters.number" />
    </TextInput>,

    <ReferenceArrayInput alwaysOn source="userId" reference="users">
        <AutocompleteArrayInput fullWidth optionText="name" label="resources.userLuckyNumbers.filters.userName" />
    </ReferenceArrayInput>,

    <ReferenceArrayInput alwaysOn source="raffleId" reference="raffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="resources.userLuckyNumbers.filters.raffleTitle" />
    </ReferenceArrayInput>,

];

const UserLuckyNumberActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

export const UserLuckyNumberList = () => (
    <List
        filters={UserLuckyNumberFilters}
        actions={<UserLuckyNumberActions />}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />

            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <FunctionField source="number" render={record => record?.number ? String(record.number).padStart(7, "0") : null} />
        </Datagrid>
    </List>
);