import { Datagrid, NumberField, ReferenceField, TextField } from "react-admin";

export const ResellersTab = (props: {
    metrics: Record<string, any> | null;
}) => {
    return (
        <div>
            {
                (props.metrics?.general?.topResellers?.length)
                    ? (
                        <>
                            <Datagrid data={props.metrics?.general?.topResellers} sort={{ field: "affiliateId", order: "ASC" }}>
                                <ReferenceField label="Revendedor" reference="affiliates" source="affiliateId" link="show">
                                    <TextField source="name" />
                                </ReferenceField>

                                <NumberField label="Valor vendido" source="soldValue" options={{ style: 'currency', currency: 'BRL' }} />
                            </Datagrid>
                        </>
                    ) 
                    : (
                        <div className="p-3 text-center">
                            <span className="block mt-2 text-base text-red-500">De acordo com os filtros selecionados, nenhuma venda feita por revendedor foi encontrada.</span>
                        </div>
                    )
            }
        </div>
    );
}