import { Edit, FormTab, NumberInput, ReferenceInput, required, SelectInput, TabbedForm } from "react-admin";
import { PaymentMethods, PaymentStatus } from "../Payments";

export const PaymentEdit = () => {
    return (
        <Edit>
            <TabbedForm>
                <FormTab label="Dados do pagamento">
                    <ReferenceInput disabled source="userId" reference="users" validate={[required()]}>
                        <SelectInput disabled optionText="name" />
                    </ReferenceInput>

                    <SelectInput disabled source="method" choices={PaymentMethods} />

                    <SelectInput source="status" validate={[required()]} choices={PaymentStatus} />
                    <NumberInput disabled source="value" options={{ style: "currency", currency: "BRL" }} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};