import { Typography } from "@mui/material";
import { AutocompleteArrayInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { FormDataConsumer, PasswordInput, email, required, useEditContext } from "react-admin";
import { AccessLevelOptions, RaffleKindOptions } from "../../../core/RaffleOptions";

export const ConsumersForm = ({ title }) => {
    const isEdit = Boolean(useEditContext().record);

    return (
        <SimpleForm>
            <Typography variant="h5" mb={3}>{title}</Typography>

            <TextInput
                source="name"
                fullWidth
                className="w-full"
                validate={[required()]}
            />

            <div className="flex-1">
                <SelectInput
                    choices={AccessLevelOptions}
                    fullWidth
                    label="Tipo de acesso ao sorteio"
                    source="raffleAccessType"
                    validate={[required()]}
                />
            </div>

            <FormDataConsumer>
                {
                    (({ formData }) => formData.raffleAccessType === "ON_DEMAND" &&
                        <ReferenceArrayInput
                            reference="raffles"
                            source="raffles"
                        >
                            <AutocompleteArrayInput fullWidth optionText="title" />
                        </ReferenceArrayInput>
                    )
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {
                    (({ formData }) => formData.raffleAccessType === "BY_KIND" &&
                        <div className="flex-1">
                            <SelectInput
                                choices={RaffleKindOptions}
                                fullWidth
                                label="Filtro de tipo de sorteio"
                                source="raffleKindFilter"
                                validate={[required()]}
                            />
                        </div>
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    );
};
