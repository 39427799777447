import { FormHelperText, Typography } from "@mui/material";
import { useEffect } from "react";
import { AutocompleteInput, DateTimeInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, maxLength, maxValue, minLength, minValue, number, required, useNotify, useRedirect } from "react-admin";
import { useFormContext } from "react-hook-form";
import { createActionRequest, createDataProviderRequest } from "../../../core/Actions";
import { BrazilianRegions } from "../../../core/BrazilianRegions";

const Form = () => {
    const { setValue } = useFormContext();
    const quinaNumbersAmount = 5;

    useEffect(() => {
        createDataProviderRequest("get", "/dailyRaffles/helpers/quina").then(async (response) => {
            const jsonResponse = await response.json();

            setValue("contest", String(jsonResponse.concurso), { shouldValidate: true, shouldDirty: true });

            if (Array.isArray(jsonResponse.dezenas)) {
                for (const [index, value] of jsonResponse.dezenas.entries()) {
                    setValue(`quinaNumbers.${index}`, value, { shouldValidate: true, shouldDirty: true });
                }
            }
        });
    }, []);

    return (
        <>
            <Typography variant="h5" mb={3}>
                Gerando resultado de sorteio diário com Quina
            </Typography>

            <DateTimeInput
                source="datetime"
                defaultValue={new Date()}
                validate={required()}
                autoFocus
            />

            <TextInput 
                source="title"
                helperText="Título para o sorteio desta hora. Por exemplo, 'PIX da Tarde', 'PIX da Noite', etc. Pessoas verão este título."
                validate={[required(), minLength(1)]}
                className="w-full"
            />

            <SelectInput
                source="region"
                helperText="Se selecionado, apenas usuários desta região participarão do sorteio."
                className="w-full"
                emptyText={"Todos os estados"}
                defaultValue={null}
                choices={ BrazilianRegions }
            />

            <NumberInput
                source="winnersCount"
                fullWidth
                helperText="Mínimo de 10 conforme requisitos legais."
                validate={[required(), minValue(10), maxValue(10_000)]}
            />

            <TextInput
                source="prizeTitle"
                helperText="Título do prêmio que será dado às pessoas contempladas. Por exemplo: 'R$ 100,00'."
                validate={[required(), minLength(1)]}
                className="w-full"
            />

            <ReferenceInput
                source="parentRaffleId"
                reference="raffles"
                filter={{
                    kind: "MAIN"
                }}
            >
                <AutocompleteInput 
                    optionText="title"
                    label="Sorteio pai"
                    className="w-full"
                    helperText="CONFIRA ANTES DE ENVIAR. Somente números comprados neste sorteio pai participaram deste sorteio diário."
                    clearIcon={false}
                    validate={required()}
                />
            </ReferenceInput>

            <NumberInput
                source="contest"
                fullWidth
                validate={[required(), minValue(1)]}
                helperText="Número do concurso da Quina."
            />

            <div>
                <div className="flex justify-center gap-3">
                    {
                        Array(quinaNumbersAmount).fill(null).map((_, index) => {
                            return (
                                <TextInput
                                    source={`quinaNumbers.${index}`}
                                    validate={[required(), minLength(2), maxLength(2), number("Deve ser um número com dois dígitos")]}
                                    label={`Dezena ${ index + 1 }`}
                                    helperText={false}
                                />
                            );
                        })
                    }
                </div>

                <FormHelperText className="ml-3">A ordem das dezenas neste formulário é irrelevante.</FormHelperText>
            </div>
        </>
    )
}

export const DailyRaffleCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    return (
        <SimpleForm
            onSubmit={async (values) => {
                try {
                    // Pushes to remote
                    await createActionRequest("post", "dailyRaffles", -1, "generateResult", {
                        ...values
                    });

                    // Notifies creation
                    notify("ra.notification.created", {
                        type: "info",
                        messageArgs: { smart_count: 1 },
                    });

                    // Redirects back to the list
                    redirect("list", "dailyRaffles");
                } catch (err) {
                    console.error(err);

                    notify("Ocorreu um erro ao processar a requisição.", {
                        type: "error",
                    });
                }
            }}
        >
            <Form />
        </SimpleForm>
    );
}