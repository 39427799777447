import { Typography } from "@mui/material";
import { Create } from "react-admin";
import { RaffleExternalResultsForm } from "./RaffleExternalResultsForm";

export const RaffleExternalResultsCreate = () => {
    return (
        <Create>
            <Typography variant="h5" className="px-4 pt-4" mb={3}> Adicionar resultado </Typography>
            <RaffleExternalResultsForm />
        </Create>
    );
};
