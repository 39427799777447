import { Typography } from "@mui/material";
import { BooleanInput, Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";

export const PluginEdit = () => {
    return (
        <Edit>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }
            >
                <BooleanInput fullWidth source="enabled" />

                <Typography variant="h5" mb={3}>Configurar plugin</Typography>

                <TextInput fullWidth disabled source="id" />
                <TextInput fullWidth disabled source="name" />
                <TextInput fullWidth disabled source="description" />
            </SimpleForm>
        </Edit>
    )
};
