import Payments from "@mui/icons-material/Payments";
import { PaymentCreate } from "./payments/PaymentCreate";
import { PaymentEdit } from "./payments/PaymentEdit";
import { PaymentList } from "./payments/PaymentList";
import { PaymentShow } from "./payments/PaymentShow";

export const PaymentsResource = {
    list: PaymentList,
    edit: PaymentEdit,
    show: PaymentShow,
    create: PaymentCreate,
    icon: Payments
}

export * from "../../core/Payments";