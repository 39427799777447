import { Datagrid, EmailField, List, SelectField, TextField } from "react-admin";
import { AdminRoleList } from "../AdminsResource";

export const AdminList = () => (
    <List queryOptions={{
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false
    }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <SelectField source="role" choices={AdminRoleList} />
        </Datagrid>
    </List>
);
