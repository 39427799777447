import { TableCell } from "@mui/material";
import { AutocompleteArrayInput, CreateButton, Datagrid, DateField, EditButton, FilterButton, List, NumberField, ReferenceArrayInput, ReferenceField, SelectArrayInput,  ShowButton, TextField, TextInput, TopToolbar } from "react-admin";

const RaffleFilters = [
    <TextInput label="Pesquisar por título" source="title" alwaysOn />,
    <ReferenceArrayInput source="parentRaffleId" reference="raffles" alwaysOn label="Filtrar por sorteio pai">
        <AutocompleteArrayInput fullWidth optionText="title" label="Filtrar por sorteio pai" />
    </ReferenceArrayInput>
];

const RaffleActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
);

export const FederalLottoRaffleList = () => (
    <List
        hasCreate={true}
        hasEdit={true}
        hasShow={true}
        exporter={false}
        filters={RaffleFilters}
        actions={<RaffleActions/>}
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />

            <ReferenceField reference="raffles" source="parentRaffleId">
                <TextField source="title" />
            </ReferenceField>

            <NumberField source="federalLottoNumber" />

            <DateField source="drawnAt" showTime />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
                <EditButton />
            </TableCell>
        </Datagrid>
    </List>
);