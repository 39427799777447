import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { FormLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useReducer, useState } from "react";
import { AutocompleteArrayInput, Button, Create, ReferenceArrayInput, useNotify } from "react-admin";
import phoneMockup from "../../../../assets/phone-mockup.svg";
import { Configuration } from "../../../../config";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { createDataProviderRequest } from "../../../../core/Actions";

export const NotificationCreate = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [notification, setNotification] = useState<{
        title: string | null;
        message: string | null;
        url: string | null;
        sendAt: string | null;
    }>({
        title: "",
        message: "",
        url: "",
        sendAt: ""
    });

    const [targets, setTargets] = useState<{
        sms: boolean;
        push: boolean;
        whatsapp: boolean;
    }>({
        sms: false,
        push: false,
        whatsapp: false
    });

    const [segmentTargetsCount, setSegmentTargetsCount] = useState<number | "undetermined">(0);

    const updateSegment = () => {
        const data = {
            targets: Object.keys(targets).filter((t) => targets[t]).map((t) => t.toUpperCase()),
            sources
        };

        if (!data.targets.length || !data.sources.length) {
            return;
        }

        return createDataProviderRequest("post", "/notifications/segment", data)
        .then((count) => {
            return count.json();
        })
        .then((count) => {
            setSegmentTargetsCount(count.count);
        });
    };

    const [sources, setSources] = useState<
        (
            {
                type: "ALL";
            } |
            {
                type: "HAS_PURCHASE_IN" | "HAS_NO_PURCHASE_IN",
                raffleId: number[];
            } |
            {
                type: "HAS_PURCHASE_INTERVAL" | "HAS_NO_PURCHASE_INTERVAL",
                interval: {
                    startDate: string;
                    endDate: string;
                }
            }
        )[]
    >([]);

    const variables = {
        "user.name": {
            label: "Nome completo",
            example: "Usuário Teste"
        },
        "user.firstName": {
            label: "Nome",
            example: "Usuário"
        },
        "user.lastName": {
            label: "Sobrenome",
            example: "Teste"
        },
        "user.email": {
            label: "Endereço de e-mail",
            example: "email@usuario.teste"
        },
        "user.tel": {
            label: "Telefone",
            example: "(43) 99689-1253"
        },
        "reseller.link": {
            label: "Link do afiliado",
            example: "https://teste.revendavivasorte.com.br"
        },
        "reseller.slug": {
            label: "Identificador do afiliado",
            example: "teste"
        }
    };

    const notify = useNotify();

    const handleSubmit = async (e) => {
        e.preventDefault();

        notify("Aguarde, enviando mensagem...", { type: "info" });

        const data = {
            targets: Object.keys(targets).filter((t) => targets[t]).map((t) => t.toUpperCase()),
            sources,
            notification: {
                ...notification,
                sendAt: notification.sendAt || undefined
            }
        };

        try {
            if (!data.notification.url) {
                // @ts-expect-error
                delete data.notification.url;
            }

            await createDataProviderRequest("post", "/notifications/send", data);

            notify(`Sua mensagem foi enviada com sucesso.`, { type: "success" });
        } catch(e) {
            notify("Ocorreu um erro ao enviar a notificação. Por favor, confira os dados e/ou tente novamente mais tarde.", { type: "error" });
            console.error(e);
        }
    };

    let previewMessage = notification.message;
    let previewTitle = notification.title;

    const [focused, setFocused] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);

    Object.keys(variables).forEach((variable) => {
        previewMessage = previewMessage!.replace(
            new RegExp("\\{\\{\\s*" + variable + "\\s*\\}\\}", "g"),
            variables[variable].example
        );

        previewTitle = previewTitle!.replace(
            new RegExp("\\{\\{\\s*" + variable + "\\s*\\}\\}", "g"),
            variables[variable].example
        );
    });

    return (
        <Create>
            <div className="flex flex-wrap">
                <div className="w-full h-full p-5 md:w-auto md:flex-1">
                    <h1 className="text-2xl">Criando notificação</h1>

                    <form
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <TextField
                            name="title"
                            label="Título"
                            required
                            fullWidth
                            helperText="O título da notificação; não é incluído no SMS."
                            onChange={(e) => setNotification({
                                ...notification,
                                title: e.target.value
                            })}
                            value={notification.title}
                            onFocus={(e) => setFocused(e.target)}
                        />

                        <div className="flex flex-wrap">
                            <div className="w-full md:w-3/4">
                                <TextField
                                    name="message"
                                    label="Conteúdo da notificação"
                                    required
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    helperText="O corpo da notificação e/ou texto do SMS"
                                    onFocus={(e) => setFocused(e.target)}
                                    value={notification.message}
                                    onChange={(e) => setNotification({
                                        ...notification,
                                        message: e.target.value
                                    })}
                                />

                                <TextField
                                    name="url"
                                    label="URL ao clicar"
                                    required
                                    fullWidth
                                    type="url"
                                    helperText="A URL da notificação; será incluída no SMS após o texto da mensagem."
                                    value={notification.url}
                                    onChange={(e) => setNotification({
                                        ...notification,
                                        url: e.target.value
                                    })}
                                />

                                <LocalizationProvider
                                    adapterLocale="pt-BR"
                                    dateAdapter={AdapterLuxon}
                                >
                                    <DateTimePicker
                                        label="Enviar em"
                                        value={notification.sendAt}
                                        onChange={(value) => {
                                            setNotification({
                                                ...notification,
                                                sendAt: value
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        className="w-full"
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="w-full md:w-1/4">
                                <div className="pl-3">
                                    <h3>Incluir variáveis</h3>

                                    <div className="flex flex-wrap gap-3">
                                        {
                                            Object.keys(variables).map((variable) => (
                                                <button
                                                    className="p-3 text-sm rounded-md shadow-md bg-slate-50 tet-center hover:bg-slate-200"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        if (focused === null) {
                                                            return;
                                                        }

                                                        const index = focused.name.replace("notification.", "");

                                                        setNotification({
                                                            ...notification,
                                                            [index]: notification[index] + "{{" + variable + "}}"
                                                        });

                                                        forceUpdate();

                                                        focused!.focus();
                                                    }}
                                                >
                                                    {variables[variable].label}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <FormLabel>
                                <Switch
                                    onChange={(e) => {
                                        targets.push = e.target.checked;
                                        setTargets(targets);
                                        forceUpdate();
                                        updateSegment();
                                    }}
                                />

                                Push
                            </FormLabel>

                            <FormLabel>
                                <Switch
                                    onChange={(e) => {
                                        targets.sms = e.target.checked;
                                        setTargets(targets);
                                        forceUpdate();
                                        updateSegment();
                                    }}
                                />

                                SMS
                            </FormLabel>

                            <FormLabel>
                                <Switch
                                    onChange={(e) => {
                                        targets.whatsapp = e.target.checked;
                                        setTargets(targets);
                                        forceUpdate();
                                        updateSegment();
                                    }}
                                />

                                WhatsApp
                            </FormLabel>
                        </div>

                        <div className="justify-around w-full my-5">
                            <div className="flex flex-wrap">
                                <div className="flex-auto">
                                    <h3>Segmentação</h3>

                                    <p className="mb-3 text-xs font-light text-neutral-500">
                                        {
                                            segmentTargetsCount === "undetermined"
                                                ? "Não é possível determinar a quantidade de usuários, pois um dos filtros não suporta pré-visualização."
                                                : <>
                                                    Aproximadamente <strong>{segmentTargetsCount}</strong> usuários receberão a notificação.
                                                </>
                                        }
                                    </p>
                                </div>

                                <div className="flex-initial">
                                    <Button
                                        startIcon={<Add />}
                                        size="large"
                                        label="Adicionar fonte"

                                        onClick={(e) => {
                                            e.preventDefault();

                                            sources.push({
                                                type: "ALL"
                                            });

                                            forceUpdate();
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                sources.length === 0 ?
                                    <div className="text-sm text-neutral-500">Nenhuma fonte foi adicionada ainda.</div> :
                                    sources.map((source, i) => (
                                        <div key={i} className="w-full p-3 my-3 border rounded border-neutral">
                                            <Button
                                                startIcon={<Remove />}
                                                label="Remover"
                                                onClick={() => {
                                                    sources.splice(i, 1);
                                                    forceUpdate();
                                                }}
                                            />

                                            <Select
                                                label="Tipo da fonte"

                                                fullWidth

                                                onChange={(e) => {
                                                    source.type = e.target.value as any;
                                                    setSources(sources);

                                                    forceUpdate();
                                                    updateSegment();
                                                }}
                                            >
                                                <MenuItem value="USERS">Todos os usuários</MenuItem>
                                                <MenuItem value="RESELLERS">Todos os revendedores</MenuItem>

                                                { /** Small spacing between group of choices */ }
                                                <MenuItem disabled />

                                                <MenuItem value="INACTIVE_USERS">Usuários inativos (logou há mais de 1 sem.)</MenuItem>
                                                <MenuItem value="ACTIVE_USERS">Usuários ativos (logou há menos de 1 sem.)</MenuItem>
                                                <MenuItem value="ENGAGED_USERS">Usuários engajados (logou múltiplas vezes há menos de 1 sem.)</MenuItem>

                                                { /** Small spacing between group of choices */ }
                                                <MenuItem disabled />

                                                <MenuItem value="HAS_PURCHASE_IN">Usuários que compraram nos sorteios selecionados</MenuItem>
                                                <MenuItem value="HAS_NO_PURCHASE_IN">Usuários que não compraram nos sorteios selecionados</MenuItem>

                                                { /** Small spacing between group of choices */ }
                                                <MenuItem disabled />

                                                <MenuItem value="HAS_PURCHASE_INTERVAL">Usuários que compraram no intervalo atribuído</MenuItem>
                                                <MenuItem value="HAS_NO_PURCHASE_INTERVAL">Usuários que não compraram no intervalo atribuído</MenuItem>
                                            </Select>

                                            {
                                                (
                                                    source.type === "HAS_PURCHASE_IN" ||
                                                    source.type === "HAS_NO_PURCHASE_IN"
                                                ) && (
                                                    <ReferenceArrayInput
                                                        reference="raffles"
                                                        source="raffleId"

                                                        queryOptions={{
                                                            refetchInterval: false,
                                                            refetchIntervalInBackground: false,
                                                            refetchOnWindowFocus: false
                                                        }}
                                                    >
                                                        <AutocompleteArrayInput
                                                            optionText="title"
                                                            label="Nome do sorteio"
                                                            fullWidth
                                                            helperText={false}
                                                            clearIcon={false}

                                                            className="mt-3"

                                                            onChange={(e) => {
                                                                (source as any).raffleId = e;
                                                                setSources(sources);
                                                                updateSegment();
                                                            }}
                                                        />
                                                    </ReferenceArrayInput>
                                                )
                                            }

                                            {
                                                (
                                                    source.type === "HAS_PURCHASE_INTERVAL" ||
                                                    source.type === "HAS_NO_PURCHASE_INTERVAL"
                                                ) && (
                                                    <div className="flex items-center">
                                                        <LocalizationProvider
                                                            adapterLocale="pt-BR"
                                                            dateAdapter={AdapterLuxon}
                                                        >
                                                            <DateTimePicker
                                                                label="De"
                                                                value={source.interval?.startDate}
                                                                onChange={(value) => {
                                                                    source.interval = source.interval || {};
                                                                    source.interval.startDate = (value as any as DateTime).toISO();
                                                                    setSources(sources);
                                                                    forceUpdate();
                                                                    updateSegment();
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat="dd/MM/yyyy HH:mm"
                                                            />

                                                            <span className="mx-3 text-sm text-neutral-500">até</span>

                                                            <DateTimePicker
                                                                label="Até"
                                                                value={source.interval?.endDate}
                                                                onChange={(value) => {
                                                                    source.interval = source.interval || {};
                                                                    source.interval.endDate = (value as any as DateTime).toISO();
                                                                    setSources(sources);
                                                                    forceUpdate();
                                                                    updateSegment();
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat="dd/MM/yyyy HH:mm"
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                            }
                        </div>

                        <Button label="Salvar" type="submit" variant="contained" size="medium" />
                    </form>
                </div>

                {/* Preview */}
                <div className="w-full md:w-auto md:flex-initial">
                    <div className="relative p-2 bg-neutral-50">
                        <img draggable="false" src={phoneMockup} />

                        <div className="absolute z-[999] left-0 top-0 w-full h-full">
                            <div className="pt-12 mx-10">
                                <div className="my-3 mt-8">
                                    <strong>Notificações</strong>
                                </div>

                                {
                                    targets.push && (
                                        <div className="p-5 mt-5 text-xs font-light bg-white rounded-md shadow">
                                            <div className="flex items-center mb-2 text-primary">
                                                <i className="mr-1 fa fa-fw fa-bell"></i> {previewTitle || "Título da notificação"}
                                            </div>

                                            <span className="whitespace-pre-wrap text-neutral-500">{previewMessage || "Mensagem da notificação"}</span>
                                        </div>
                                    )
                                }

                                {
                                    targets.sms && (
                                        <div className="p-5 mt-5 text-xs font-light bg-white rounded-md shadow">
                                            <div className="flex items-center mb-2 text-teal-500">
                                                <i className="mr-1 fa fa-fw fa-envelope"></i> SMS
                                            </div>

                                            <span className="whitespace-pre-wrap text-neutral-500">{previewMessage || "Mensagem da notificação"}</span>
                                            <div className="text-primary">{notification.url}</div>
                                        </div>
                                    )
                                }

                                {
                                    targets.whatsapp && (
                                        <div className="p-5 mt-5 text-xs font-light bg-white rounded-md shadow">
                                            <div className="flex items-center mb-2 text-[#128C7E]">
                                                <i className="mr-1 fab fa-fw fa-whatsapp"></i> {Configuration.title ?? "LuckyMaker"}
                                            </div>

                                            <div className="mb-1 font-bold text-neutral-500">{previewTitle || "Título da notificação"}</div>
                                            <span className="whitespace-pre-wrap text-neutral-500">{previewMessage || "Mensagem da notificação"}</span>
                                            <div className="text-primary">{notification.url}</div>

                                            <div className="flex gap-3 mt-3">
                                                <div className="text-[#128C7E] uppercase py-1">Responder</div>
                                                <div className="text-[#128C7E] uppercase py-1">Marcar como lida</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Create>
    );
};
