import { useState } from "react";

import {
    Confirm,
    useNotify,
    useRecordContext
} from "react-admin";

import { createActionRequest } from "../../../core/Actions";

export const RaffleDraw = ({
    open, setOpen
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleDialogClose = () => setOpen(false);

    const record = useRecordContext();
    const notification = useNotify();

    const handleConfirm = async () => {
        setIsLoading(true);

        try {
            await createActionRequest("post", "raffles", record.id, "doDraw");

            notification("Sorteado com sucesso!", { type: "success" });
        } catch(e) {
            notification((e as any).message, { type: "error" });
            setIsLoading(false);
        } finally {
            setOpen(false);
        }
    };

    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Realizar sorteio"
                content="Você tem certeza que deseja realizar o sorteio?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};