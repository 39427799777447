import { AuthProvider } from "react-admin";
import { Configuration } from "../config";

interface IIdentity {
    id: number;
    name: string;
    email: string;
    raffles: number[];
    avatar: string;
    role: string;
    acl: boolean | Record<string, boolean>;
}

let identity: IIdentity | Promise<IIdentity>;

export const createAuthorizedRequest = (url: string, opts: RequestInit = {}) => new Request(url, {
    method: "GET",
    ...opts,
    headers: new Headers({
        ...opts.headers || {},
        authorization: "Bearer " + localStorage.getItem("admin-token")
    }),
});

async function getIdentity() {
    if (!identity) {
        const request = createAuthorizedRequest(Configuration.baseUrl + "/session");

        const response = identity = await fetch(request) as any;

        // If the session fetch failed
        if (response.status < 200 || response.status > 299) {
            throw new Error((await response.json()).message);
        }

        const data = await response.json();

        identity = data;
    } else
    if (identity instanceof Promise) {
        await identity;
    }

    const finishedIdentity = identity as IIdentity;

    return {
        id: finishedIdentity.id,
        fullName: finishedIdentity.name,
        avatar: finishedIdentity.avatar,
        role: finishedIdentity.role,
        acl: finishedIdentity.acl
    };;
}

export const authProvider: AuthProvider = {
    async login(params: any) {
        const { username, password } = params;

        const request = new Request(Configuration.baseUrl + "/authenticate", {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                "content-type": "application/json"
            })
        });

        const response = await fetch(request);

        // If the login failed
        if (response.status < 200 || response.status > 299) {
            throw new Error((await response.json()).message);
        }

        const { token } = await response.json();

        localStorage.setItem("admin-token", token);

        return true;
    },

    async logout() {
        localStorage.removeItem("admin-token");
    },

    async checkError(e) {
        // Whenever it's a TreatedError
        // @TODO: Decouple those status codes.
        if ((e.status === 400 || e.status === 413) && e.message) {
            return Promise.resolve(e);
        }

        // Whenever it's a not found error (whether from path resolver, or by trying to get a non-existing resource)
        if (e.status === 404) {
            return Promise.resolve(e);
        }

        console.error("untreated error: %O", e);
    },

    async checkAuth() {
        if (!localStorage.getItem("admin-token")) {
            throw new Error("Sessão inválida.");
        }

        try {
            await getIdentity();
        } catch(e: any) {
            throw new Error(e.message);
        }
    },

    async getIdentity() {
        return getIdentity();
    },

    async getPermissions() {
        if (!identity) {
            await this.getIdentity!();
        }

        const permissions = (identity as IIdentity)?.acl;
        const final: Record<string, any> = {};

        if (typeof permissions === "boolean") {
            return true;
        }

        for(let key in permissions) {
            if (typeof permissions[key] === "boolean") {
                final[key] = {
                    enabled: permissions[key]
                };
            } else {
                final[key] = {
                    enabled: true,
                    ...permissions[key] as any
                };
            }
        }

        return final;
    }
};