import { Typography } from "@mui/material";
import { Create, DateTimeInput, NumberInput, SelectInput, SimpleForm, required, useGetIdentity, usePermissions } from "react-admin";
import { CouponBenefitTypes, MAX_ADMIN_COUPON_VALUE, MAX_SAC_COUPON_VALUE } from "../Coupons";
import { AdminRoles } from "../system/AdminsResource";

export const CouponCreate = () => {
    const { isLoading } = usePermissions();
    const { isLoading: isLoadingIdentity, identity } = useGetIdentity();

    if (isLoading || isLoadingIdentity) {
        return null;
    }

    const getUserRole =() => {
        return identity?.fullName || null;
    }

    const validateMaxCouponValue = (value: number) => {
        const userRole = getUserRole();
        const maxValue = userRole === AdminRoles.SAC ? MAX_SAC_COUPON_VALUE : MAX_ADMIN_COUPON_VALUE;

        if (value > maxValue) {
            return `O tipo de benefício escolhido para o cupom não pode ultrapassar o máximo de ${maxValue}.`;
        }
    };

    return (
        <Create redirect="show">
            <SimpleForm>
                <Typography variant="h5" mb={3}>Criar cupom</Typography>

                <div className="flex flex-col w-full gap-3 md:flex-row">
                    <NumberInput
                        fullWidth
                        source="benefitAmount"
                        validate={[validateMaxCouponValue, required()]}
                    />

                    <SelectInput
                        fullWidth
                        disabled
                        source="benefitType"
                        defaultValue="CREDITS"
                        choices={CouponBenefitTypes}
                        validate={[required()]}
                    />
                </div>

                <DateTimeInput fullWidth source="expiresAt" validate={[required()]} />
                <span className="text-sm opacity-50">O código do cupom será gerado automaticamente.</span>
            </SimpleForm>
        </Create>
    )
};
