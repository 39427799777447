import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import { NotificationCreate } from "./notifications/NotificationCreate";
import { NotificationList } from "./notifications/NotificationList";
import { NotificationShow } from "./notifications/NotificationShow";

export const NotificationsResource = {
    create: NotificationCreate,
    list: NotificationList,
    show: NotificationShow,
    icon: PhoneAndroid
}