import { TableCell } from "@mui/material";
import { CreateButton, Datagrid, DateField, DateInput, EditButton, FilterButton, List, NumberField, NumberInput, SelectField, ShowButton, TextField, TextInput, TopToolbar } from "react-admin";
import { RaffleKinds, RaffleStatus } from "../Raffles";

const RaffleFilters = [
    <TextInput label="Pesquisar por título" source="title" alwaysOn />,
    <NumberInput label="ID do sorteio" source="id" alwaysOn />
];

const RaffleActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
);

export const RaffleList = () => (
    <List
        hasCreate={true}
        hasEdit={true}
        hasShow={true}
        exporter={false}
        filters={RaffleFilters}
        actions={<RaffleActions/>}
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />
            <SelectField source="kind" choices={RaffleKinds} />
            <NumberField source="price" options={{ style: "currency", currency: "BRL" }} />
            <SelectField source="status"  choices={RaffleStatus} />
            <DateField source="drawDate" showTime={true} />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
                <EditButton />
            </TableCell>
        </Datagrid>
    </List>
);