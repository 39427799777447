import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

export const GraphTab = (props: {
    queryData: Record<string, any>;
    data?: Record<string, any>[];
}) => {
    const { data, queryData } = props;

    return (
        <div className="relative w-full aspect-[21/6.5]">
            <ResponsiveContainer className="absolute" aspect={3} width="100%">
                <LineChart
                    width={1280}
                    height={720}
                    data={data}
                    margin={{
                        left: 35,
                        right: 0,
                        top: 15,
                        bottom: 15
                    }}
                >
                    <CartesianGrid
                        strokeDasharray="3 3"
                    />

                    <XAxis
                        dataKey="mensurableMetric"
                        tick={{fontSize: "12px"}}
                    />

                    <YAxis
                        tickFormatter={(value) => new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                        }).format(value)}
                        tick={{fontSize: "12px"}}
                    />

                    <Tooltip
                        content={(props) => {
                            if (props.payload?.length === 0) {
                                return null;
                            }

                            const payload = props.payload?.[0].payload;

                            if (!payload) {
                                return null;
                            }

                            const currencyFormatter = new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL"
                            });

                            return (
                                <div className="p-3 text-sm bg-white rounded-md shadow-md">
                                    {
                                        !payload.metadata ? (
                                            <>
                                                <p className="mb-3 font-bold">
                                                    {payload.mensurableMetric}
                                                </p>

                                                <p>
                                                    {`Número total de pagamentos: `}
                                                    <strong>{payload.totalPaymentsCount}</strong>
                                                </p>

                                                <p>
                                                    {`Quantidade de vendas aprovadas: `}
                                                    <strong>{payload.totalApprovedCount}</strong>
                                                </p>

                                                <p className="intro">
                                                    {`Valor total não pago: `}
                                                    <strong>{currencyFormatter.format(payload?.totalNonApprovedValue)}</strong>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    Object.keys(payload.metadata).map((key) => (
                                                        <p key={key}>
                                                            {key + ": "}
                                                            <strong>{currencyFormatter.format(payload.metadata[key].totalApprovedValue)}</strong>
                                                        </p>
                                                    ))
                                                }       
                                            </>
                                        )
                                    }
                                </div>
                            );
                        }}
                    />

                    {
                        queryData.paymentMeta.length === 0 ? (
                            <>
                                <Line
                                    type="monotone"
                                    name="Valor total aprovado"
                                    dataKey="totalApprovedValue"
                                    stroke="#29CC39"
                                    activeDot={{ r: 8 }}
                                    connectNulls={true}
                                />

                                <Line
                                    type="monotone"
                                    name="Valor total não pago"
                                    dataKey="totalNonApprovedValue"
                                    stroke="#FF0000"
                                    strokeDasharray="3 3"
                                    connectNulls={true}
                                />
                            </>
                        ) : Object.keys(
                            data?.reduce((obj, report) => {
                                return {
                                    ...obj,
                                    ...report.metadata
                                };
                            }, {} as any)
                        ).map((tag) => (
                            <Line
                                key={"line-" + tag}
                                type="monotone"
                                name={tag}
                                dataKey={(k) => k.metadata[tag]?.totalApprovedValue}
                                stroke={'#' + Math.random().toString(16).substr(-6)}
                                strokeDasharray="3 3"
                                connectNulls={true}
                            />
                        ))
                    }

                    <Legend />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};