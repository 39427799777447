import { Datagrid, useGetList, ReferenceField, NumberField, TextField, Show, DateField, TabbedShowLayout, Tab, useShowContext } from "react-admin";
import { SendSecondWayButton } from "../../shared/SendSecondWayButton";

export const UserPurchaseHistory = () => {
    const { record } = useShowContext();

    const { data, isLoading } = useGetList("userLuckyNumbers", {
        filter: {
            userId: record?.id
        }
    });

    return <>
        {
            !isLoading &&
                <Datagrid 
                    sort={{
                        field: "id",
                        order: "ASC"
                    }}
                    data={data}
                >
                    <ReferenceField label="Pagamento" source="paymentId" reference="payments" link="show">
                        <TextField label="ID do pagamento" source="id" />
                    </ReferenceField>

                    <ReferenceField label="Sorteio" source="raffleId" reference="raffles">
                        <TextField source="title" />
                    </ReferenceField>

                    <DateField label="Data e hora" source="createdAt" />

                    <SendSecondWayButton />
                </Datagrid>
        }
</>;
}

export const UserShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Dados básicos">
                    <TextField source="email" />

                    <TextField source="originalName" />

                    <TextField source="socialName" emptyText="-" />

                    <TextField source="cpf" />

                    <TextField source="tel" />

                    <DateField source="birthdate" />
                </Tab>

                <Tab label="Endereço">
                    <TextField source="cep" />
                    <TextField source="address" />
                    <TextField source="data.address.number" />
                    <TextField source="data.address.complement" />
                    <TextField source="neighborhood" />
                    <TextField source="city" />
                    <TextField source="state" />
                </Tab>

                <Tab label="Histórico de compras">
                    <UserPurchaseHistory />
                </Tab>

                <Tab label="Financeiro">
                    <NumberField source="creditsAmount" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};