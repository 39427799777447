import { Typography } from "@mui/material";
import { Edit } from "react-admin";
import { RaffleExternalResultsForm } from "./RaffleExternalResultsForm";

export const RaffleExternalResultsEdit = () => (
    <Edit>
        <Typography variant="h5" className="px-4 pt-4" mb={3}> Editar resultado </Typography>
        <RaffleExternalResultsForm />
    </Edit>
);
