import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const ConteSuaHistoriaShow = () => (
    <Show
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <SimpleShowLayout>
            <TextField source="id" />

            <TextField source="fields.name" />
            <TextField source="fields.phone" />
            <TextField source="fields.email" />

            <TextField source="fields.city" />
            <TextField source="fields.state" />
            <TextField source="fields.address" />

            <TextField source="fields.message" />

            <DateField source="createdAt" showTime />
        </SimpleShowLayout>
    </Show>
);