import { MenuItemLink, UserMenu } from "ra-ui-materialui";
import { ExitToApp, Smartphone } from "@mui/icons-material";
import { useLogout } from "react-admin";

export const CustomUserMenu = (props) => {
    const logout = useLogout();

    return <UserMenu {...props}>
        <MenuItemLink
            to="/user/add-device"
            primaryText="Conectar dispositivo"
            leftIcon={<Smartphone />}
        />

        <MenuItemLink
            to=""
            primaryText="Sair da aplicação"
            onClick={() => logout()}
            leftIcon={<ExitToApp />}
        />
    </UserMenu>
};