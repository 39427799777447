import { Button, Datagrid, DateField, DateTimeInput, Form, FunctionField, Link, List, ReferenceField, SelectField, SelectInput, TextField, TextInput, TopToolbar, WithRecord, required, useDataProvider, useGetList, useListController, useNotify, useRecordContext } from "react-admin";
import { createActionRequest } from "../../../core/Actions";
import { FinancialOrigin, FinancialOriginSubtype, FinancialsStatus } from "../../../core/FinancialsStatus";
import DownloadIcon from '@mui/icons-material/GetApp';
import { DateTime } from 'luxon';
import edjlogo from "../../../assets/edjlogo.png";
import { useEffect, useState } from "react";

export const StatusCircle = () => {
    const record = useRecordContext();

    return <>
        <div className="flex gap-3">
            <div className="flex items-center justify-center">
                <div className={`inline-block w-[10px] h-[10px] aspect-square rounded-full 
                    ${record.status === "PAID" && `bg-green-500`} 
                    ${record.status === "PENDING" && `bg-red-500`}
                    ${record.status === "ANALYSIS" && `bg-yellow-500`}
                `} />
            </div>
        </div>
    </>;
}

export const StatusSelect = (props) => {
    const record = useRecordContext();
    const notify = useNotify();
    const { refetch } = useListController();

    return <>
        <Form>
            <div className="flex">
                <SelectInput 
                    className="w-full"
                    validate={required()}
                    source="status"
                    choices={FinancialsStatus}
                    helperText={false}

                    onChange={async (event) => {
                        const { value } = event.target;
                        
                        await createActionRequest("post", "financials", record.id, "changeStatus", {
                            newStatus: value
                        });

                        await refetch();

                        notify("ra.notification.updated", {
                            type: "success",
                            messageArgs: { smart_count: 1 },
                        });
                    }}
                />
            </div>
        </Form>
    </>;
};

const AddNote = (props) => {
    const record = useRecordContext();
    const [notes, setNote] = useState(record.notes);
    const notify = useNotify();
    const [timer, setTimer] = useState<any>(null);

    useEffect(() => {
        setNote(record.notes);
    }, [record.notes]);

    return <>
        <Form>
            <div className="flex">
                <TextInput
                    className="w-full"
                    multiline
                    source="notes"
                    helperText={false}
                    value={notes}
                    onChange={(event) => {
                        const { value } = event.target;

                        setNote(value);

                        clearTimeout(timer);
                        const newTimer = setTimeout(async () => {
                            await createActionRequest("post", "financials", record.id, "addNote", {
                                notes: value
                            });
    
                            notify("ra.notification.updated", {
                                type: "success",
                                messageArgs: { smart_count: 1 },
                            });
                        }, 800);

                        setTimer(newTimer);
                    }}
                />
            </div>
        </Form>
    </>;
}

const Filters = [
    <SelectInput
        choices={ FinancialsStatus }
        alwaysOn
        fullWidth
        source="status"
        label="Pesquisar status"
        emptyText={"Todos"}
    />,
    <SelectInput
        choices={ FinancialOriginSubtype }
        alwaysOn
        fullWidth
        source="origin:subtype"
        label="Tipos de sorteio"
        emptyText={"Todos"}
    />,
    <DateTimeInput alwaysOn fullWidth source="raffle:date$gte" label="Sorteados desde" />,
    <DateTimeInput alwaysOn fullWidth source="raffle:date$lte" label="Sorteados até" />
];

const ExportRecords = () => {
    const dataProvider = useDataProvider();

    async function exportList() {
        const list = await dataProvider.getList("financials", {
            pagination: { page: 1, perPage: 1000 },
            filter: { status: { $ne: "PAID" } },
            sort: { field: "raffle.date", order: "ASC" }
        });
        
        const wind: Window = window.open("", "", "height=500, width=500") as Window;

        let tableBody = "";

        for (const item of list.data) {
            const status = FinancialsStatus.find(status => status.id === item.status)?.name;
            const origin = FinancialOriginSubtype.find(origin => origin.id === item.origin.subtype)?.name;

            tableBody += `
                <tr>
                    <td>${item.winner.name}</td>
                    <td>${item.raffle.title}</td>
                    <td>${origin}</td>
                    <td>${
                        DateTime.fromISO(item.raffle.date)
                            // Set to Brasília time
                            .setZone("UTC-3")
                            .toFormat("dd/MM/yyyy HH:mm")
                    }</td>
                    <td>${item.winner.prizeName}</td>
                    <td>${status}</td>
                </tr>
            `;
        }

        let pdfToPrint = `
            <body>
                <div class="page">
                    <div class="header">
                        <h1 class="title">
                            Relatório de Contas 
                        </h1>
                    </div>
                    <div class="overview">
                        <div class="company">
                            <img 
                                src="${edjlogo}" 
                                alt="logo"
                                class="logo"
                                width="160"
                                height="100"
                            />
                            <div class="infos">
                                <h1 class="companyname">EDJ INTERMEDIACOES DE NEGOCIOS LTDA</h1>
                                <div>
                                    <p>Av Paraná, 307</p>
                                    <p>41.809.598/0001-41</p>
                                    <p>43991776763</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Contemplado</th>
                                    <th>Sorteio</th>
                                    <th>Tipo do Sorteio</th>
                                    <th>Data do Sorteio</th>
                                    <th>Prêmio</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            ${tableBody}
                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

            <style>
                @media print {
                    * {
                        -webkit-print-color-adjust: exact;
                        color-adjust: exact;
                    }
                    @page {
                        size: A4 landscape; 
                    }
                }
                
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                body * {
                    /* border: 0.1rem solid red; */
                    font-family: 'Roboto', sans-serif;
                }
                

                .page {
                    display: flex;
                    flex-direction: column;
                    width: 150vw;
                    height: 100vh;
                    padding: 4rem;
                    gap: 2rem;
                }

                .header {
                    display: flex;
                }
                
                .header .title {
                    font-size: 2.3rem;
                    font-weight: 700;
                }

                .companyname {
                    font-size: 1.7rem;
                    font-weight: 700;
                    color: #575757
                }

                .overview {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
                    gap: 2rem;
                }

                .company {
                    display: flex;
                    flex-direction: row;
                    gap: 2.5rem;
                    align-items: center;
                }

                .infos {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                .infos div {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                .infos p {
                    font-size: 1.4rem;
                    font-weight: 300;
                    color: rgb(119, 119, 119);
                    margin: 0;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;
                }

                th,
                td {
                    text-align: left;
                    padding: 8px;
                    color: #575757;

                }

                th {
                    background-color: #f1f4f9;
                    color: #575757;
                    font-weight: 700;
                }

                tbody tr {
                    border-bottom: 1px solid #e9e9e9;
                }
            </style>
        `;

        wind.document.write(pdfToPrint);
        wind.document.close();
        wind.print();
        wind.onafterprint = () => {
            wind.close();
        };
    }

    return <Button startIcon={<DownloadIcon/>} onClick={() => exportList()} label="Exportar PDF" />
};

const Actions = () => (
    <TopToolbar>
        <ExportRecords />
    </TopToolbar>
);

export const FinancialsList = () => (
    <List
        actions={<Actions />}
        filters={Filters}
    >
        <Datagrid 
            sort={{ field: "raffle.date", order: "ASC" }} 
            rowClick={() => false} 
            bulkActionButtons={false}
        >
            <StatusCircle />

            <DateField source="raffle.date" showTime={true} />

            <AddNote source="notes" />
            
            <WithRecord 
                label="Contemplado"
                render={
                    (record) => (
                        (record.winner.id)
                            ? (
                                <ReferenceField reference="users" source="winner.id" emptyText={record.winner.name}>
                                    <TextField source="name" />
                                </ReferenceField>
                            )
                            : <TextField source="winner.name" />
                    )
                } 
            />

            <WithRecord 
                label="Tipo do sorteio"
                render={
                    (record) => (
                        {
                            "RAFFLE": (
                                <ReferenceField source="origin.id" reference="raffles" emptyText="Sorteio (apagado)" link="show">
                                    <FunctionField render={(raffleRecord) => raffleRecord.kind === 'MAIN' ? 'Sorteio Principal' : 'Hora do Viva'} />
                                </ReferenceField>
                            ),
                            "DAILY_RAFFLE": (
                                // This is written as Link because ReferenceField was not working with dailyRaffles.
                                <Link to={`/dailyRaffles/${record.origin.id}/show`}>PIX Diário</Link>
                            )
                        }[record.origin.type] ?? (
                            <FunctionField render={() => 'Indefinido'} />
                        )
                    )
                } 
            />

            <TextField source="winner.prizeName" />

            <StatusSelect source="status" />
        </Datagrid>
    </List>
);