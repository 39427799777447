import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { AdminCreate } from "./admins/AdminCreate";
import { AdminEdit } from "./admins/AdminEdit";
import { AdminList } from "./admins/AdminList";

export const AdminsResource = {
    list: AdminList,
    edit: AdminEdit,
    create: AdminCreate,
    icon: VerifiedUser,
};

 export enum AdminRoles {
    SUPERADMIN = "SUPERADMIN",
    CLIENT = "CLIENT",
    SAC = "SAC",
    MARKETING = "MARKETING",
    FINANCIAL = "FINANCIAL"
};

export const AdminRoleList = [
    { id: AdminRoles.SUPERADMIN, name: "Super-administrador" },
    { id: AdminRoles.CLIENT, name: "Cliente" },
    { id: AdminRoles.SAC, name: "SAC" },
    { id: AdminRoles.MARKETING, name: "Marketing" },
    { id: AdminRoles.FINANCIAL, name: "Financeiro" }
];

