import { Typography } from "@mui/material";
import { AutocompleteArrayInput, ReferenceArrayInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { PasswordInput, email, required, useEditContext } from "react-admin";

export const AmbassadorsForm = ({title}) => {
    const isEdit = Boolean(useEditContext().record);

    return (
        <SimpleForm>
            <Typography variant="h5" mb={3}>{ title }</Typography>

            <TextInput 
                source="name"
                fullWidth
                className="w-full"
                validate={[required()]}
            />

            <TextInput
                source="email"
                fullWidth
                className="w-full"
                type="email"
                validate={[required(), email()]}
            />

            <PasswordInput
                source="password"
                fullWidth
                validate={isEdit ? [] : [required()]}
            />

            <ReferenceArrayInput 
                reference="raffles" 
                source="raffles"
            >
                <AutocompleteArrayInput fullWidth optionText="title" />
            </ReferenceArrayInput>
        </SimpleForm>
    );
};