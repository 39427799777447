import { Typography } from "@mui/material";
import Html5QrcodePlugin from "../plugins/QRCodePlugin";
import { useEffect, useState } from "react";
import { createDataProviderRequest } from "../../core/Actions";

export const QRCodeLogin = () => {
    const [deviceId, setDeviceId] = useState(null);
    const [authorizationStatus, setAuthorizationStatus] = useState<boolean | null>(null);

    useEffect(() => {
        if (deviceId !== null) {
            createDataProviderRequest("post", "/admin/device/add", {
                deviceId
            })
            .then(() =>  setAuthorizationStatus(true))
            .catch((e) =>  setAuthorizationStatus(false));
        }
    }, [deviceId]);

    const onScanResult = (decodedText) => {
        setDeviceId(decodedText);
    };

    return <div className="py-5">
        <div>
            <Typography variant="h6">
                Conectar dispositivo
            </Typography>

            <div className="mb-5">
                Escaneie o código QR utilizando a câmera.
            </div>

            {
                !deviceId ?
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={300}
                    disableFlip={false}
                    qrCodeSuccessCallback={onScanResult}
                /> :
                (
                    authorizationStatus === null ?
                    (
                        <div className="text-center">
                            <span className="fa-stack fa-3x">
                                <i className="fa fa-circle-notch fa-spin fa-stack-2x"></i>
                                <i className="fa fa-laptop fa-stack-1x"></i>
                            </span>

                            <div className="mt-3">
                                Aguarde enquanto realizamos a autenticação do seu dispositivo.<br/>
                                Certifique-se de que o dispositivo esteja conectado à internet.
                            </div>
                        </div>
                    ) :
                    (
                        authorizationStatus === true ?
                        (
                            <div className="text-center">
                                <i className="fa fa-3x fa-fw fa-check text-success"></i>

                                <div className="mt-3">
                                    O dispositivo foi autorizado com sucesso!
                                </div>
                            </div>
                        ) :
                        (
                            <div className="text-center">
                                <i className="fa fa-3x fa-fw fa-ban text-danger"></i>

                                <div className="mt-3">
                                    O dispositivo falhou ao autorizar.
                                </div>
                            </div>
                        )
                    )
                )
            }
        </div>
    </div>
};