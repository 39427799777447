import { BooleanField, ReferenceArrayField, Show, SimpleShowLayout, TextField } from "react-admin";

export const NotificationShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />

            <TextField source="included_segments" />
            <TextField source="excluded_segments" />

            <ReferenceArrayField source="include_external_user_ids" reference="include_external_users">
                <TextField source="id" />
            </ReferenceArrayField>
            
            <BooleanField source="is_ios" />
            <BooleanField source="is_android" />
            <BooleanField source="is_chrome_web" />
            <BooleanField source="is_firefox" />
            <BooleanField source="is_safari" />

            <TextField source="contents.en" />
            <TextField source="headings.en" />

            <TextField source="url" />
            <TextField source="web_url" />
            <TextField source="app_url" />

            <TextField source="buttons" />
        </SimpleShowLayout>
    </Show>
);