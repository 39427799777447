import { Datagrid, FunctionField, List, NumberField, ReferenceField, SelectField, TextField } from "react-admin";
import { ReapprovePaymentButton } from "../../../shared/ReapprovePaymentButton";
import { PaymentStatus } from "../../Payments";

export const ApprovedPaymentsWithoutNumberList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            
            <SelectField source="status" choices={PaymentStatus} />
            
            <NumberField source="value" options={{ style: "currency", currency: "BRL" }} />

            <FunctionField source="items.amount" render={(record) => record.items.reduce((accumulator, item) => accumulator += item.amount, 0)} />

            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <ReapprovePaymentButton />
        </Datagrid>
    </List>
);