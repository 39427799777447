import { TableCell } from "@mui/material";
import { AutocompleteArrayInput, CreateButton, Datagrid, EditButton, FilterButton, List, NumberInput, ReferenceArrayInput, ShowButton, TextField, TopToolbar } from "react-admin";

const PartnerFilters = [
    <NumberInput label="ID do parceiro" source="id" alwaysOn />,
    <ReferenceArrayInput alwaysOn key="name" source="name" reference="partners">
        <AutocompleteArrayInput optionText="name" fullWidth source="name" />
    </ReferenceArrayInput>
];

const PartnerActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
);

export const PartnerList = () => (
    <List
        hasCreate={true}
        hasEdit={true}
        hasShow={true}
        exporter={false}
        filters={PartnerFilters}
        actions={<PartnerActions/>}
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
                <EditButton />
            </TableCell>
        </Datagrid>
    </List>
);
