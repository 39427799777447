/**
 * To use in text inputs, and so on.
 * @returns 
 */
export const validCpf = () => (value) => {
    if (value && !validateCPF(value)) {
        return "CPF inválido";
    }
    return undefined;
};

/**
 * Note: this code was copied from LuckyMaker's source code.
 */
export function validateCPF(cpf: string) {
    let sum: number = 0;
    let rest: number;

    // Strip all non-numeric characters
    cpf = cpf.replace(/[^0-9]/g, "");

    // Check for the common invalid CPFs
    if (cpf === "0".repeat(11) || cpf === "1".repeat(11) || cpf === "2".repeat(11)) {
        return false;
    }

    for(let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    // If the sum doesn't match
    if (rest !== parseInt(cpf.substring(9, 10))) {
        return false;
    }

    sum = 0;
    
    for(let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    // If the sum doesn't match
    if (rest !== parseInt(cpf.substring(10, 11))) {
        return false;
    }

    return true;
}