import { Configuration } from "../config";
import { createAuthorizedRequest } from "./AuthProvider";

export function objectToQueryString(url: URL, obj: Record<string, any>) {
    for(let param in obj) {
        const type = typeof obj[param];
        const value = obj[param];

        if (type === "object") {
            url.searchParams.set(param, JSON.stringify(value));
        } else {
            url.searchParams.set(param, String(value));
        }
    }

    return url;
}

export async function createDataProviderRequest<R = any>(
    method: "get" | "post" | "delete", 
    path: string,
    data?: any,
    params?: Record<string, any> & {
        query?: Record<string, any> & {
            range?: [number, number];
            attributes?: string[];
            sort?: [string, "ASC" | "DESC"];
        };
    }
) {
    // Do not allow paths to start with /
    if (path.startsWith("/")) {
        path = path.substring(1);
    }

    const baseUrl = new URL(Configuration.baseUrl);

    // Create the URL that will be sent
    const url = new URL(baseUrl.pathname + "/" + path, baseUrl);

    // If has a query
    if (!!params?.query) {
        // Add it to the URL
        objectToQueryString(url, params.query);
    }

    const headers: Record<string, any> = {};

    // If "data" is an object
    if (typeof data === "object") {
        // If it's POSTing
        if (method === "post") {
            // Convert the data into JSON
            data = JSON.stringify(data);
            headers["content-type"] = "application/json";
        } else
        // If it's GETing
        if (method === "get") {
            // Convert the data into a query string
            objectToQueryString(url, data);
        }
    }

    const response = await fetch(
        createAuthorizedRequest(url.toString(), {
            method: method,
            body: method === "get" ? undefined : data,
            headers: headers
        })
    );

    if (response.status < 200 || response.status > 299) {
        const error = new Error((await response.json()).message) as any;
        error.response = response;

        throw error;
    }

    return response as Response & {
        status: number;
        json(): Promise<R>;
    };
}

export const createActionRequest = (
    method: "get" | "post",
    resource: string,
    item: string | number,
    action: string,
    data?: any
) => {
    return createDataProviderRequest(method, resource + "/" + item + "/actions/" + action, data);
}
