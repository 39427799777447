import { Datagrid, List, NumberField, TextField } from 'react-admin';

export const DatabaseList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="user" />
            <TextField source="command" />
            <NumberField source="time" />
            <TextField source="state" />
            <TextField source="info" />
        </Datagrid>
    </List>
);