import { Typography } from "@mui/material";
import { Create, FormDataConsumer, PasswordInput, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, email, required } from "react-admin";
import { AdminRoleList } from "../AdminsResource";

export const AdminCreate = () => (
    <Create>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Criar administrador</Typography>

            <TextInput source="name" />

            <TextInput source="email" type="email" validate={[required(), email()]} />

            <PasswordInput source="password" validate={[required()]} />

            <SelectInput source="role" choices={AdminRoleList} validate={[required()]} />

            <FormDataConsumer>
                {({ formData }) =>
                    formData.role !== "SUPERADMIN" ? (
                        <ReferenceArrayInput reference="raffles" source="raffles">
                            <SelectArrayInput optionText="title" />
                        </ReferenceArrayInput>
                    ) : (
                        <Typography color="rgba(255, 255, 255, 0.4)">
                            Super-administradores tem acesso à todos os sorteios.
                        </Typography>
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
