import { Typography } from "@mui/material";
import { Create, DateInput, email, maxValue, minLength, minValue, PasswordInput, required, SimpleForm, TextInput } from "react-admin";
import { validCpf } from "../../validators/Cpf";
import { fullName } from "../../validators/User";

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Criando conta de usuário</Typography>

            <TextInput 
                source="originalName"
                className="w-full"
                validate={[required(), fullName()]}
            />

            <TextInput 
                source="socialName"
                className="w-full"
            />

            <TextInput
                source="email"
                className="w-full"
                type="email"
                validate={[
                    required(),
                    email(),
                ]}
            />

            <TextInput 
                source="cpf"
                validate={[required(), validCpf()]}
                helperText="Não é necessário inserir hífen ou pontos"

                // Removes non-digits
                parse={(value) => value?.replace(/[^0-9]/g, "")}
            />

            <TextInput 
                source="address.cep"
                validate={[
                    required(),
                    minLength(8)
                ]}
                helperText="Não é necessário inserir hífen ou pontos"

                // Removes non-digits
                parse={(value) => value?.replace(/[^0-9]/g, "")}
            />

            <TextInput 
                source="tel"
                validate={[required()]}
                helperText="Não é necessário inserir hífen, parênteses ou espaçamento"

                // Removes non-digits
                parse={(value) => value?.replace(/[^0-9]/g, "")}
            />

            <PasswordInput
                source="password"
                validate={[
                    required(),
                    minLength(8)
                ]}
                helperText="Deve ter pelo menos 8 caracteres"
            />

            <DateInput 
                source="birthdate"
                validate={[
                    required(),
                    minValue("1900-01-01"),
                    maxValue(new Date())
                ]}
                helperText="Nota: É proibida a venda de título de capitalização a menores de dezesseis anos - Art. 3.º, I do Código Civil."
            />
        </SimpleForm>
    </Create>
);