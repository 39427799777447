import { Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Button, useNotify } from "react-admin";
import { DefaultPresets, Preset } from "./result-generator/Presets";
import { ResultGenerator } from "./result-generator/ResultGenerator";
import { ResultGeneratorConfigContext } from "./result-generator/ResultGeneratorContext";
import { RaffleListItem, ResultRaffleKind, getRaffleList, retrieveRaffleAsIntermediateResult } from "./result-generator/ResultGeneratorHelpers";

export interface IntermediateRaffleData {
    id: number;
    name: string;
    drawDate: string;
}

export interface IntermediateRafflePrize {
    id: number;
    name: string;
    raffleKind: "MATRIXES" | string;
    drawnNumbers?: number[];
    order?: number;
    time?: `${string}:${string}` | string;

    winners: {
        name: string;
        number: number;
        photoUrl?: string;

        city?: string;
        state?: string;

        reseller?: {
            name: string;
        };
    }[];
}

export interface IntermediateRaffleResult extends IntermediateRaffleData {
    prizes: IntermediateRafflePrize[];
}

export type IntermediateRaffleResultSet = IntermediateRaffleResult[];

export const ResultGeneratorPage = () => {
    const [raffleDate, setDate] = useState<string | null>(
        localStorage.getItem("lm/results/lastSelectedDate") ?? DateTime.now().setZone("UTC-3").toFormat("dd/MM/yyyy")
    );

    const [winnersPerImage, setWinnersPerImage] = useState<number>(5);
    const [backgroundImage, setFile] = useState<any>();
    const [primaryColor, setPrimaryColor] = useState<string>("#000000");
    const [secondaryColor, setSecondaryColor] = useState<string>("#000000");
    const [tertiaryColor, setTertiaryColor] = useState<string>("#000000");
    const [presets, setPresets] = useState<Preset[]>([]);
    const [raffleKind, setRaffleKind] = useState<ResultRaffleKind | null>(null);

    const [results, setResults] = useState<IntermediateRaffleResultSet>([]);

    const [selectedRaffle, setSelectedRaffle] = useState<string | "all" | null>(null);
    const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>("");
    const [subtitle, setSubtitle] = useState<string | null>("");
    const [groupByTitle, setGroupByTitle] = useState(false);

    const [raffleList, setRaffleList] = useState<RaffleListItem[]>([]);

    const notify = useNotify();

    /**
     * Makes the result array.
     */
    async function makeResults() {
        // Ignore if there's no selected raffle
        if (selectedRaffle === null) {
            return;
        }

        // If wants the results for all raffles
        if (selectedRaffle === "all") {
            // Retrieve the results for all raffles
            setResults(
                await Promise.all(
                    raffleList.map((r) =>
                        retrieveRaffleAsIntermediateResult(raffleKind as ResultRaffleKind, r.id)
                    )
                )
            )
        } else {
            // Gather the raffle information
            setResults([
                await retrieveRaffleAsIntermediateResult(raffleKind as ResultRaffleKind, selectedRaffle as string)
            ]);
        }
    }

    // When raffle kind or date changes
    useEffect(() => {
        // Update the raffle list
        getRaffleList({
            raffleKind: raffleKind as ResultRaffleKind,
            date: raffleDate ? DateTime.fromFormat(raffleDate, "dd/MM/yyyy").toFormat("yyyy-MM-dd") : undefined
        })
            .then((list) => {
                // Set the new raffle list
                setRaffleList(list as RaffleListItem[]);

                // If "all" is selected, call the result maker
                if (selectedRaffle === "all") {
                    makeResults();
                }
            });

        // If has a raffle date, save it
        if (raffleDate) {
            localStorage.setItem("lm/results/lastSelectedDate", raffleDate);
        }
    }, [raffleKind, raffleDate]);

    // When the selected raffle changes
    useEffect(() => {
        // Make the results
        makeResults();
    }, [selectedRaffle]);

    // Run once
    useEffect(() => {
        // If already has presets in the local storage
        if (localStorage.getItem("lm-presets")) {
            const presets = JSON.parse(
                localStorage.getItem("lm-presets") as string
            );

            setPresets(presets);
        } else {
            // Save the defaults
            localStorage.setItem(
                "lm-presets",
                JSON.stringify(DefaultPresets)
            );

            setPresets(DefaultPresets);
        }
    }, []);

    function deletePreset() {
        if (!selectedPreset) {
            notify("Nenhum preset foi selecionado. Por favor, selecione um preset para excluir", { type: "warning" });
            return;
        }

        if (confirm(`Deseja excluir o preset ${title} ?`)){
            notify("Preset excluído.", { type: "success" });
            
            presets.splice(
                presets.findIndex(preset => preset.id === selectedPreset), 1
            );

            setSelectedPreset(null);
            savePresets();
        }
    }

    function clonePreset() {
        const preset: Preset = {
            id: String(Date.now()),
            title: "Novo Preset",
            subtitle: "Subtítulo do preset",
            winnersPerImage: 5,
            colors: {
                primary: "#770665",
                secondary: "#770665",
                tertiary: "#000000"
            }
        };

        if (confirm(`Deseja criar um novo preset ?`)) {
            presets.push(preset);

            notify(`Preset ${preset.title} criado com sucesso.`, { type: "success" });
            
            savePresets();

            selectPreset(preset.id);
        }
    }

    function savePresets() {
        const serialized = JSON.stringify(presets);
        localStorage.setItem("lm-presets", serialized);
        setPresets(JSON.parse(serialized));
    }

    function savePreset() {
        if (!selectedPreset) {
            notify("Selecione um preset para salvar", { type: "warning" });
            return;
        }
        
        if (confirm(`Deseja salvar o preset "${title}"?`)) {
            const currentPreset: Preset = presets.find((p) => p.id === selectedPreset) as Preset;

            currentPreset.title = title!;

            if (currentPreset.subtitle) {
                currentPreset.subtitle = subtitle!;
            }

            currentPreset.winnersPerImage = winnersPerImage;
            currentPreset.colors.primary = primaryColor;
            currentPreset.colors.secondary = secondaryColor;
            currentPreset.colors.tertiary = tertiaryColor;
            currentPreset.groupByTitle = groupByTitle;

            savePresets();

            notify("Preset salvo.", { type: "success" });
        }
    }

    function handleBgImageInputChange(event) {
        const file = event.target.files[0];
        
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setFile(reader.result);
        };

        reader.onerror = (error) => {
            console.error(error);
        };
    }

    function selectPreset(presetName: string) {
        const preset = presets.find((p) => p.id === presetName) as Preset;

        console.log(preset, presetName);

        setSelectedPreset(preset.id);
        setTitle(preset.title);

        setWinnersPerImage(preset.winnersPerImage);
        
        if (preset.subtitle) {
            setSubtitle(preset.subtitle);
        }

        setPrimaryColor(preset.colors?.primary); 
        setSecondaryColor(preset.colors?.secondary);
        setTertiaryColor(preset.colors?.tertiary);
        setGroupByTitle(preset.groupByTitle ?? false);
    }

    function resetFiltersAndResults() {
        setResults([]);
        setRaffleList([]);
        setSelectedRaffle(null);
    }

    function handleKind(result) {
        setRaffleKind(result);

        resetFiltersAndResults();
    }

    function setRaffleDate(date) {
        setDate(date);

        resetFiltersAndResults();
    }
    
    return (
        <div className="py-5">
            <div className="flex justify-between w-full">
                <Typography variant="h6">Gerador de pós-resultados</Typography>
                
                <div className="flex items-center justify-center gap-3">
                    <select
                        className="font-bold bg-transparent font-ubuntu"
                        onChange={(e) => selectPreset(e.target.value)}
                        defaultValue="initial"
                    >
                        <option disabled value="initial">Selecione um preset</option>
                        {
                            presets.map((preset) => (
                                <option
                                    value={preset.id}
                                    key={preset.id}
                                    label={preset.title}
                                />
                            ))
                        }
                    </select>
                </div>

                <div className="flex items-center justify-center gap-5">
                    <Button 
                        size="small"
                        label="Salvar Preset"
                        onClick={savePreset}
                        variant="contained"
                    />

                    <button onClick={clonePreset}>
                        <i className="fa-solid fa-plus text-primary" title="Criar novo preset" />
                    </button>

                    <button onClick={deletePreset}>
                        <i className="fa-solid fa-trash text-primary" title="Excluir preset selecionado" />
                    </button>                    
                </div>
            </div>

            <div className="flex items-center justify-start gap-3 px-4 mt-5">                    
                <TextField
                    label="Data do sorteio"
                    value={raffleDate}
                    onChange={(e) => setRaffleDate(e.target.value)}
                />

                <select
                    defaultValue={raffleKind as string ?? "initial"}
                    className="flex-1 p-4 bg-transparent border border-gray-300 rounded-md font-ubuntu"
                    onChange={(e) => handleKind(e.target.value)}
                >
                    <option disabled value="initial" label="Selecione o tipo do sorteio" />
                    
                    <option value="MAIN" label="Principal" />
                    <option value="SECONDARY" label="Secundário" />
                    <option value="DAILY" label="Diario" />
                </select>

                <select
                    defaultValue={selectedRaffle as string ?? "initial"}
                    className="flex-1 p-4 bg-transparent border border-gray-300 rounded-md font-ubuntu"
                    onChange={(e) => setSelectedRaffle(e.target.value)}
                >
                    <option disabled value="initial">Selecione um</option>
                    <option value="all">Todos</option>

                    {
                        raffleList?.map((raffle: any) => (
                            <option 
                                key={raffle.id}
                                value={raffle.id} 
                                label={`${raffle?.id ?? ""} - ${raffle?.title}`} 
                            />
                        ))
                    }
                </select>
            </div>

            <hr className="my-5 border-t border-gray-300" />

            <div className="my-3">
                <TextField
                    fullWidth
                    label="Título do sorteio"
                    helperText="O título do sorteio"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />

                <TextField
                    fullWidth
                    label="Subtitulo do sorteio"
                    helperText="O subtitulo do sorteio"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                />

                <TextField
                    type="color"
                    label="Cor primária"
                    helperText="A cor primária"
                    value={primaryColor}
                    onChange={(e) => setPrimaryColor(e.target.value)}
                    className="w-1/3"
                />

                <TextField
                    type="color"
                    label="Cor secundária"
                    helperText="A cor secundária"
                    value={secondaryColor}
                    onChange={(e) => setSecondaryColor(e.target.value)}
                    className="w-1/3"
                />

                <TextField
                    type="color"
                    label="Cor terciária"
                    helperText="A cor terciária"
                    value={tertiaryColor}
                    onChange={(e) => setTertiaryColor(e.target.value)}
                    className="w-1/3"
                />

                <TextField
                    fullWidth
                    type="number"
                    label="Ganhadores por imagem"
                    helperText="O número de ganhadores inseridos por imagem / página"
                    value={winnersPerImage}
                    onChange={(e) => setWinnersPerImage(Number(e.target.value))}
                />

                <FormGroup className="mb-3">
                    <FormControlLabel
                        control={
                            <Checkbox onChange={(e) => setGroupByTitle(e.target.checked)} />
                        }
                        label="Agrupar prêmios por título"
                    />
                    <FormHelperText>
                        Permite agrupar os prêmios dos resultados pelos seus títulos.
                    </FormHelperText>
                </FormGroup>

                <div className="flex flex-col">
                    <label htmlFor="background" className=" min-w-14">Imagem: </label>

                    <input
                        id="background"
                        placeholder="Imagem de fundo do sorteio"
                        type="file"
                        onChange={handleBgImageInputChange}
                    />
                </div>
            </div>

            <ResultGeneratorConfigContext.Provider
                value={{
                    title: title,
                    subtitle: subtitle,
                    date: raffleDate,
                    winnersPerImage: winnersPerImage || 1,
                    patternBackgroundFile: backgroundImage,
                    raffleIdentifier: selectedRaffle === 'all' ? 'ALL' : `${raffleKind}:${selectedRaffle}`,

                    colors: {
                        primary: primaryColor,
                        secondary: secondaryColor,
                        tertiary: tertiaryColor
                    },

                    groupByTitle: groupByTitle
                }}
            >
                { results &&
                    results.map((result, index) => 
                        <ResultGenerator
                            key={`generator-${index}`}
                            result={result}
                        />
                    )
                }
            </ResultGeneratorConfigContext.Provider>
        </div>
    );
};