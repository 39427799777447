import { Delete as DeleteIcon, Download } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { unparse as convertToRawCSV } from "papaparse";
import { ArrayField, Confirm, Datagrid, DateField, FunctionField, Button as AdmButton, NumberField, ReferenceField, Show, ShowButton, downloadCSV, SimpleShowLayout, TextField, useNotify, useRecordContext, useRedirect, useRefresh, useShowContext, useDataProvider, TopToolbar, SelectField } from "react-admin";
import { createActionRequest } from "../../../core/Actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Configuration } from "../../../config";
import { generateAuthenticatedURL } from "../../../utils/RequestUtils";
import { BrazilianRegions } from "../../../core/BrazilianRegions";

const DailyRaffleShowActions = () => {
    const { record } = useShowContext();

    /**
     * Performs an export action.
     * @param type The export type.
     */
    function performExport(type: "ViaCapAta" | "ViaCapReembolsoContemplados") {
        const url: URL = new URL(
            generateAuthenticatedURL("/dailyRaffles/" + record.code + "/actions/exportTo" + type)
        );

        window.open(url);
    }

    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
    const [exportAllAnchorEl, setExportAllAnchorEl] = useState(null);

    const displayURL = new URL(window.location.href);

    // Clear the hash
    displayURL.hash = "";

    // If in dev mode
    if (Configuration.isDev) {
        // Point to the local display
        displayURL.host = "localhost:8070";

        // Set the token
        displayURL.searchParams.set("access_token", localStorage.getItem("admin-token") as string);
    } else {
        // Point to the remote display
        // ATTENTION: needs to have a trailing slash
        displayURL.pathname = "/sorteador/";
    }

    // Set the raffle_id param to the current raffle ID
    displayURL.searchParams.set("raffle_id", String("daily:" + record?.id));

    return (
        <TopToolbar>
            <>
                <AdmButton
                    label="Ações"
                    aria-haspopup="true"
                    onClick={(e) => setActionsAnchorEl(e.target as any)}
                />

                <AdmButton
                    label="Exportar tudo"
                    aria-haspopup="true"
                    onClick={(e) => setExportAllAnchorEl(e.target as any)}
                />

                <Menu
                    id="actions-menu"
                    anchorEl={actionsAnchorEl}
                    keepMounted
                    open={Boolean(actionsAnchorEl)}
                    onClose={() => setActionsAnchorEl(null)}
                >
                    <a
                        href={displayURL.toString()}
                        target="_blank"

                        onClick={() => setActionsAnchorEl(null)}
                    >
                        <MenuItem>
                            <i className="mr-2 fa fa-fw fa-arrow-up-right-from-square"></i> Abrir no display
                        </MenuItem>
                    </a>
                </Menu>

                <Menu
                    id="export-all-menu"
                    anchorEl={exportAllAnchorEl}
                    keepMounted
                    open={Boolean(exportAllAnchorEl)}
                    onClose={() => {
                        setExportAllAnchorEl(null);
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setExportAllAnchorEl(null);
                            performExport("ViaCapAta");
                        }}
                    >
                        Ata do Sorteio / ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAllAnchorEl(null);
                            performExport("ViaCapReembolsoContemplados");
                        }}
                    >
                        Contemplados / Reembolso / ViaCap
                    </MenuItem>
                </Menu>
            </>
        </TopToolbar>
    );
};

const DeleteButton = () => {
    const { record: rootRecord } = useShowContext();
    const innerRecord = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();
    const [confirming, setConfirming] = useState(false);
    
    return (
        <>
            <Button
                className="!text-danger" 
                endIcon={<DeleteIcon/>}
                onClickCapture={() => setConfirming(true)}
            >
                Apagar
            </Button>

            <Confirm
                isOpen={confirming}
                title="Apagar sorteio"
                content={`Você tem certeza que deseja apagar o sorteio das ${ innerRecord.time }?`}
                onConfirm={async () => {
                    await createActionRequest("post", "dailyRaffles", rootRecord.id, "removeResult", {
                        time: innerRecord.time
                    });

                    // Notifies deletion
                    notify("ra.notification.deleted", {
                        type: "info",
                        messageArgs: { smart_count: 1 },
                    });

                    setConfirming(false);

                    // Was the last one being removed
                    if (rootRecord.raffles.length === 1) {
                        redirect("list", "dailyRaffles");
                    } else {
                        refresh();
                    }
                }}
                onClose={() => setConfirming(false)}
            />
        </>
    )
}

const ExportButton = () => {
    const innerRecord = useRecordContext();
    const dataProvider = useDataProvider();
    
    const ExportPrizes = async (inner) => {
        console.log(inner)
        const fullPrizes = await Promise.all(
            inner.prizes.map(async (prize) => {  
                let user;
                try {
                    prize.user.id && (user = await dataProvider.getOne("users", { id: prize.user.id }))
                } catch (err: any) {
                    if (err?.status !== 404) {
                        throw err;
                    }
                    console.warn(err);
                }
                return {
                    ...prize.user,
                    tel: user?.data?.tel || "APAGADO"
                }
            })
        )

        const rawCSV = convertToRawCSV({
            data: fullPrizes,
            fields: [
                "name",
                "tel"
            ],
        });
    
        downloadCSV(
            rawCSV, 
            // This will be used as the downloaded file name.
            `sorteio-${inner.time}`
        ); 
    }

    return (
        <>
            <Button
                endIcon={<Download />}
                onClick={async()=>{await ExportPrizes(innerRecord)}}
            >
                Exportar
            </Button>
        </>
    );
}

const Contemplations = () => {
    const innerRecord = useRecordContext();

    // To save some server bandwidth, the contemplations list is only shown when requested
    const [lazyLoadRequested, setLazyLoadRequested] = useState<boolean>(false);

    return (
        <Accordion onClick={() => setLazyLoadRequested(true)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="flex"
            >
                <Typography className="flex items-center">
                    Contemplações
                </Typography>

                <Chip
                    label={innerRecord.prizes.length}
                    className="ml-auto"
                />
            </AccordionSummary>

            <AccordionDetails>
                {
                    lazyLoadRequested &&
                        <SimpleShowLayout>
                            <ReferenceField reference="raffles" source="parentRaffle.id" emptyText="Não encontrado">
                                <TextField source="title" />
                            </ReferenceField>

                            <SelectField
                                source="forRegion"
                                label="Região sorteada"
                                emptyText="Todas"
                                choices={ BrazilianRegions }
                            />

                            <NumberField 
                                source="quina.contest"
                                label="Concurso da Quina"
                                emptyText="Não há"
                            />

                            <FunctionField
                                source="quina.tens"
                                label="Dezenas da Quina"
                                render={
                                    (value) => (
                                        value.quina?.tens
                                            ? value.quina?.tens?.map?.((v) => String(v).padStart(2, "0")).join(" ")
                                            : "Não há"
                                    )
                                }
                            />

                            <ArrayField source="prizes">
                                <Datagrid bulkActionButtons={false}>
                                    <FunctionField 
                                        source="luckyNumber.number" 
                                        render={(value) => String(value.luckyNumber.number).padStart(7, "0")}
                                    />

                                    <TextField source="user.name" />

                                    <TextField source="reseller.name" />

                                    <ReferenceField reference="userLuckyNumbers" source="luckyNumber.id" link="show" label="Detalhes do Título">
                                        <ShowButton label="Acessar" />
                                    </ReferenceField>
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                }
            </AccordionDetails>
        </Accordion>
    );
}

const Layout = () => {
    return (
        <SimpleShowLayout>
            <DateField source="date" />

            <TextField source="code" />

            <ArrayField source="raffles">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="time" />
                    <TextField source="title" />

                    <Contemplations />

                    <ExportButton />

                    <DeleteButton />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
}

export const DailyRaffleShow = () => {
    return (
        <Show actions={<DailyRaffleShowActions />}>
            <Layout />
        </Show>
    );
}