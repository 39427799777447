import { Typography } from "@mui/material";
import { useState } from "react";
import { ArrayInput, AutocompleteInput, Button, Create, minValue, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";
import { Configuration } from "../../../config";

export const PaymentCreate = () => {
    const [queryData, setQueryData] = useState("MAIN");

    return (
        <Create>
            <SimpleForm>
                <Typography variant="h5" mb={1}>Registrando pagamento manualmente</Typography>
                <Typography variant="subtitle1" mb={3}>Ao registrar o pagamento, os itens serão entregues normalmente ao usuário como se fosse uma compra aprovada comum.</Typography>

                <div className="flex items-center justify-end gap-3 my-3">
                    <Button
                        onClick={() => setQueryData("SECONDARY")}
                        label="Hora do Viva"
                        color={queryData === "SECONDARY" ? "secondary": "primary"}
                    />

                    <Button
                        onClick={() => setQueryData("MAIN")}
                        label={Configuration.title ?? "LuckyMaker"}
                        color={queryData === "MAIN" ? "secondary": "primary"}
                    />
                </div>
            
                <ReferenceInput
                    source="userId"
                    reference="users"
                    queryOptions={{
                        refetchInterval: false,
                        refetchIntervalInBackground: false,
                        refetchOnWindowFocus: false
                    }}
                >
                    <AutocompleteInput
                        validate={[required()]}
                        className="w-full"
                        optionText={(choice) => `${choice.cpf} - ${choice.name}`}
                        label="CPF ou Nome do usuário"
                        autoSelect
                        clearIcon={false}
                        helperText={false}
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="raffleId"
                    reference="raffles"
                    queryOptions={{
                        refetchInterval: false,
                        refetchIntervalInBackground: false,
                        refetchOnWindowFocus: false
                    }}
                    filter={{
                        kind: queryData
                    }}
                >
                    <AutocompleteInput
                        validate={[required()]}
                        className="w-full"
                        optionText="title"
                        label="Sorteio"
                        autoSelect
                        clearIcon={false}
                        helperText={false}
                    />
                </ReferenceInput>

                <SelectInput 
                    source="method"
                    className="w-full"
                    type="email"
                    validate={[required()]}
                    choices={[
                        { id: "PIX", name: "Pix" },
                        { id: "CARD", name: "Cartão de crédito" },
                        { id: "PICPAY", name: "PicPay" },
                    ]}
                    defaultValue="PIX"
                />

                <ArrayInput source="items">
                    <SimpleFormIterator>
                        <SelectInput 
                            source="type"
                            className="w-full"
                            type="email"
                            validate={[required()]}
                            choices={[
                                { id: "RAFFLE_NUMBERS", name: "Título de Capitalização" }
                            ]}
                            defaultValue="RAFFLE_NUMBERS"
                        />

                        <NumberInput 
                            source="amount"
                            className="w-full"
                            validate={[ required(), minValue(1) ]}
                            helperText="O valor será calculado automaticamente com base neste número"
                            step={1}
                            defaultValue={1}
                            min={1}
                            max={500}

                            // Transforms to an integer 
                            parse={value => parseInt(value)}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput
                    source="notes"
                    className="w-full"
                    multiline
                    label="Notas adicionais para revisões e auditorias (opcional)"
                    helperText="Você também pode comentar em detalhes o porque foi necessário criar o pagamento, caso julgue necessário."
                />
            </SimpleForm>
        </Create>
    );
};

export default PaymentCreate;