import { DateTimeInput, Edit, FormTab, ImageField, ImageInput, NumberInput, required, SelectInput, TabbedForm, TextInput, useTranslate } from "react-admin";

import { RichTextInput } from "ra-input-rich-text";
import { ColorInput } from "react-admin-color-picker";
import { RaffleKinds, RaffleStatus } from "../Raffles";
import { RaffleEntropyTab } from "./tabs/RaffleEntropyTab";

export const RaffleEdit = () => {
    const translate = useTranslate();

    return (
        <Edit>
            <>
                <style>{/*css*/`
                    .RaRichTextInput-editorContent .ProseMirror {
                        min-height: 200px;
                    }
                `}
                </style>

                <TabbedForm>
                    <FormTab label={translate("resources.raffles.tabs.data")}>
                        <TextInput fullWidth source="title" validate={[required()]} />

                        <SelectInput fullWidth source="kind" validate={[required()]} choices={RaffleKinds} />

                        <TextInput fullWidth source="edition" validate={[required()]} />

                        <RichTextInput fullWidth source="description" validate={[required()]} />

                        <TextInput multiline fullWidth source="excerpt" validate={[required()]} />

                        <ImageInput
                            fullWidth
                            accept="image/png, image/jpg, image/jpeg"
                            source="thumbnail"
                        >
                            <ImageField fullWidth source="src" title="title" />
                        </ImageInput>

                        <TextInput fullWidth source="slug" validate={[required()]} />

                        <NumberInput fullWidth source="price" options={{ style: "currency", currency: "BRL" }} validate={[required()]} />

                        <SelectInput fullWidth source="status" validate={[required()]} choices={RaffleStatus} />

                        <TextInput fullWidth source="settings.susepProcess" />
                        <RichTextInput fullWidth source="settings.regulation" />

                        <DateTimeInput helperText="A data e hora no qual o sorteio inicia as vendas." fullWidth source="startAt" validate={[required()]} />
                        <DateTimeInput helperText="A data e hora no qual o sorteio encerra as vendas." fullWidth source="endAt" validate={[required()]} />

                        <DateTimeInput helperText="A data e hora no qual o sorteio será realizado." fullWidth source="drawDate" validate={[required()]} />
                    </FormTab>

                    <FormTab label={translate("resources.raffles.tabs.entropy")}>
                        <RaffleEntropyTab />
                    </FormTab>

                    <FormTab label={translate("resources.raffles.tabs.style")}>
                        <ColorInput source="settings.colors.highlight" picker="Sketch" />
                    </FormTab>

                    <FormTab label={translate("resources.raffles.tabs.live")}>
                        <TextInput fullWidth type="url" source="settings.whereToWatch.facebook" />
                        <TextInput fullWidth type="url" source="settings.whereToWatch.youtube" />
                        <TextInput fullWidth type="url" source="settings.whereToWatch.twitch" />
                    </FormTab>
                </TabbedForm>
            </>
        </Edit>
    );
}
