import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { createActionRequest } from "../../../../core/Actions";
import { useEffect, useState } from "react";
import { useNotify } from "react-admin";

const dummy: any = {

}

export const setGenerateNumbersOpen = (open: boolean) => dummy.setModalOpen(open);

export const GenerateNumbersAction = ({ raffleId }: { raffleId: number }) => {
    const [open, setModalOpen] = useState(false);
    const [isGenerating, setGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [error, setError] = useState(null);

    dummy.open = open;
    dummy.setModalOpen = setModalOpen;

    const notify = useNotify();

    useEffect(() => {
        if (open && !isGenerating && !hasGenerated) {
            setGenerating(true);
            setHasGenerated(true);

            createActionRequest("post", "raffles", raffleId, "generateNumbers")
            .then(async (response) => {
                setGenerating(false);

                if (response.status !== 200) {
                    setError((await response.json()).message);

                    notify(error as any as string, {
                        type: "error"
                    });
                }
            });
        }
    });

    return (
        <Dialog
            open={open}
            onClose={() => setModalOpen(false)}
        >
            <DialogTitle>
                { isGenerating? "Gerando números..." : (error ?"Erro" : "Finalizado") }
            </DialogTitle>

            <DialogContent>
                {
                    isGenerating ?
                    (
                        <>
                            Aguarde enquanto os números do sorteio estão sendo gerados...
                            <p>Seja paciente, esta operação pode levar algum tempo.</p>

                            <div style={{ textAlign: "center" }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) :
                    (
                        !error ? "Geração completa, você pode fechar esta tela." :
                        (
                            <p>{error}</p>
                        )
                    )

                }
            </DialogContent>
        </Dialog>        
    );
};