import { Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField } from "react-admin";

export const ConsumerShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />

                <TextField source="name" />

                <TextField source="apiKey" />

                <TextField source="apiSecret" />

                <TextField source="raffleAccessType" />

                {/* //! @TODO: Change to only show if raffleAccessType is ON_DEMAND */}
                <TextField source="raffleKindFilter" />
            </SimpleShowLayout>
        </Show>
    )
};