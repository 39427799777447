import { createDataProviderRequest } from "../../../core/Actions";
import { removeUndefined } from "../../../utils/ObjectUtils";

import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "react-admin";
import { useQuery } from "react-query";

export interface MetaSelectorTag {
    key: string | null | undefined;
    value?: string | null | undefined;
}

export const MetaSelector = (opts: {
    raffle?: number | undefined | null;
    onAddTag?: (tag: MetaSelectorTag) => any;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectingTag, setSelectingTag] = useState<MetaSelectorTag | null>(null);
    const [tags, setTags] = useState<MetaSelectorTag[]>([]);

    const updateData = () => {
        // If there's a raffle selected
        if (!!opts.raffle) {
            setIsLoading(true);

            // Retrieve the metas for it
            createDataProviderRequest("get", "paymentMeta/list", removeUndefined({
                // Only include the values if there's a meta KEY selected
                includeValues: !!selectingTag?.key,
                key: selectingTag?.key,
                raffleId: opts.raffle
            }))
                .then(async (data) => {
                    const jsonData = (await data.json());

                    setTags(jsonData);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };

    useQuery(["paymentMetaList"], updateData);
    useEffect(updateData, [opts.raffle, selectingTag?.key]);

    return (
        <div className="p-3 min-w-[25rem]">
            <Autocomplete
                disabled={isLoading}
                loading={isLoading}

                options={
                    tags
                        .filter((tag, index) =>
                            tags.findIndex((t) => t.key === tag.key) === index
                        )
                        .map((tag) => ({ label: tag.key }))
                    }
                
                renderInput={(params) =>
                    <TextField {...params} label="Marcador" />
                }

                onChange={(_, tag) => {
                    setSelectingTag({
                        key: tag?.label
                    })
                }}
                
                value={{ label: selectingTag?.key ?? "" }}

                className="mb-3"
            />

            <Autocomplete
                disabled={isLoading}
                loading={isLoading}

                options={
                    tags
                        .filter((tag) => tag.key === selectingTag?.key)
                        .map((tag) => ({ label: tag.value }))
                }

                renderInput={(params) =>
                    <TextField {...params} label="Valor do marcador" />
                }

                onChange={(value, tag) => {
                    setSelectingTag({
                        key: selectingTag?.key,
                        value: tag?.label
                    })
                }}

                onEmptied={() => setSelectingTag(selectingTag ? {
                    key: selectingTag.key
                } : null)}

                value={{ label: selectingTag?.value || "" }}
            />

            <Button
                label="Adicionar"

                disabled={!Boolean(selectingTag)}

                onClick={async () => {
                    if (opts.onAddTag) {
                        await opts.onAddTag(selectingTag as MetaSelectorTag);
                    }

                    setSelectingTag(null);
                }}

                className="block w-full mt-5"
            />
        </div>
    )
};