import { TextField, Show, SimpleShowLayout, ReferenceField, SelectField, useShowContext, Button, NumberField, useNotify, DateField} from "react-admin";
import { createActionRequest } from "../../../../core/Actions";
import { AffiliateActivityTypes, AffiliateWithdrawStatus } from "../../Affiliates";

const AffiliateActivityShowLayout = () => {
    const { isLoading, record } = useShowContext();
    const notification = useNotify();

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    const handleWithdrawStatusChange = async (newStatus: "APPROVED" | "REFUSED") => {
        try {
            await createActionRequest("post", "affiliateActivity", record.id, "updateStatus", {
                newStatus: newStatus
            });

            notification(`O saque foi ${newStatus === "APPROVED" ? "aprovado" : "recusado"} com sucesso!`, { type: "success" });
        } catch(e) {
            notification((e as any).message, { type: "error" });
        }
    };

    return (
        <SimpleShowLayout>
            <ReferenceField label="Revendedor" reference="affiliates" source="affiliateId">
                <TextField label="Revendedor" source="name" />
            </ReferenceField>

            <SelectField label="Atividade" source="type" choices={AffiliateActivityTypes} />

            <DateField label="Data de hora" showTime source="createdAt" />

            {
                record.type === "WITHDRAW" && (
                    <NumberField label="Valor" source="data.total" options={{ style: "currency", currency: "BRL" }} />
                )
            }

            {
                record.type === "WITHDRAW" && (
                    <SelectField label="Status" source="data.status" choices={AffiliateWithdrawStatus} />
                )
            }

            {
                record.type === "WITHDRAW" && record.data.status === "PENDING" && (
                    <>
                        <Button
                            onClick={() => handleWithdrawStatusChange("APPROVED")}
                            size="medium"
                            type="button"
                            color={"success" as any}
                            label="Aprovar"
                        />

                        <Button
                            onClick={() => handleWithdrawStatusChange("REFUSED")}
                            size="medium"
                            type="button"
                            color={"error" as any}
                            label="Recusar"
                        />
                    </>
                )
            }

            {
                record.type === "SLUG_CHANGED" && <div className="text-sm">
                    <hr className="my-5" />
                    
                    <h3 className="mb-3 font-bold">Usuário realizou a troca do seu identificador.</h3>

                    <div>
                        <strong>Identificador antigo:</strong> {record.data.oldSlug}
                    </div>
                    <div>
                        <strong>Novo identificador:</strong> {record.data.newSlug}
                    </div>
                </div>
            }
        </SimpleShowLayout>
    );
};

export const AffiliateActivityShow = () => (
    <Show>
        <AffiliateActivityShowLayout></AffiliateActivityShowLayout>
    </Show>
);