import { Datagrid, List, NumberField, TextField } from "react-admin";

export const MatrixList = () => (    
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />

            <TextField source="name" />

            <NumberField source="size" />

            <NumberField source="numbersPerBond" />
        </Datagrid>
    </List>
);  