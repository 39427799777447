import { Typography } from "@mui/material";
import { SimpleForm, ReferenceInput, Create, AutocompleteInput, useCreateContext, FormDataConsumer } from "react-admin";

export const RaffleResultCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <Typography variant="h5" mb={3}>Adicionar resultado</Typography>

                <ReferenceInput reference="raffles" source="raffleId">
                    <AutocompleteInput optionText="title" fullWidth label="Sorteio" />
                </ReferenceInput>

                <FormDataConsumer>
                    {
                        (({ formData }) => formData.raffleId &&
                            <ReferenceInput
                                filter={{ raffleId: formData.raffleId }}
                                reference="rafflePrizes"
                                source="prizeId"
                            >
                                <AutocompleteInput optionText="name" fullWidth label="Prêmio" />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {
                        (({ formData }) => formData.raffleId &&
                            <ReferenceInput
                                filter={{ raffleId: formData.raffleId }}
                                reference="userLuckyNumbers"
                                source="numberId"
                            >
                                <AutocompleteInput optionText="number" fullWidth label="Número da sorte" />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}