import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils, CreateParams, DataProvider, UpdateParams, CreateResult } from "react-admin";

import objectToFormData from "object-to-formdata";

import { Configuration } from "../config";

const RESTDataProvider = simpleRestProvider(Configuration.baseUrl, (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("admin-token");

    // @ts-ignore
    options.headers.set("Authorization", `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
});

const maybeParseMultipart = async (type: "update" | "create" | "create/many", resource: string, params: CreateParams | UpdateParams) => {
    // Filter out file fields
    const filteredData = Object.keys(params.data).reduce((obj, index) => {
        if (params.data[index]?.toString() === "[object Object]" && ("rawFile" in params.data[index])) {
            return obj;
        }

        obj[index] = params.data[index];

        return obj;
    }, {} as typeof params.data);

    // First of all, convert the object into form data
    const formData = objectToFormData.serialize(filteredData, {
        booleansAsIntegers: true,
        nullsAsUndefineds: true
    });

    const data: Record<string, any> = {};

    let needsToSubmitFormData = false;

    for(let param in params.data) {
        // If it's an object or array
        if (params.data[param]?.toString() === "[object Object]") {
            // If its a raw file
            if ("rawFile" in params.data[param]) {
                // Set only the file
                formData.set(param, params.data[param].rawFile);
                needsToSubmitFormData = true;

                continue;
            }
        }

        data[param] = params.data[param];
    }

    // If the form data isn't empty
    if (needsToSubmitFormData) {
        let url = Configuration.baseUrl;

        if (url.endsWith("/")) {
            url = url.substring(0, url.length - 1);
        }

        url += "/" + resource + "/" + String(type === "update" ? (params as UpdateParams).id : "");

        if (type === "create/many") {
            url += "many/";
        }

        const request = new Request(url, {
            method: type === "update" ? "PUT" : "POST",
            headers: new Headers({
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("admin-token")}`
            }),
            body: formData
        });

        const response = await fetch(request);

        if (response.status !== 200) {
            throw {
                status: response.status,
                ...await response.json()
            };
        }

        // @FIXME: @TODO: This is a workaround trying to fix the double-request issue.
        // The double-request issue occurs, for example, in Resellers Material
        // CRUD page, when trying to CREATE (not update) a material with a image.
        // After sending the first request (above), the client would try
        // to send a second request (from the last "return" call of this function).
        // I don't know exactly why this solution was created this way, so I'm
        // afraid of creating a regression by changing how this function works.
        if (type === "create") {
            return {
                data: await response.json()
            } as CreateResult;
        }
    }

    // Update the rest of the data
    return RESTDataProvider[type](resource, {
        ...params as any,
        data
    });
};

export const APIDataProvider: DataProvider = {
    ...RESTDataProvider,

    async update(resource: string, params: UpdateParams) {
        return maybeParseMultipart("update", resource, params);
    },

    async create(resource: string, params: CreateParams) {
        return maybeParseMultipart("create", resource, params);
    },

    async createMany(resource: string, params: CreateParams) {
        return maybeParseMultipart("create/many", resource, params);
    }
};