import { BooleanField, Datagrid, List, TextField } from "react-admin";

export const PluginList = () => (
    <List
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="description" />
            <BooleanField source="enabled" />
        </Datagrid>
    </List>
);
