export const PaymentMethods = [
    { id: "CARD", name: "Cartão de crédito" },
    { id: "PICPAY", name: "PicPay" },
    { id: "PIX", name: "PIX" },
    { id: "CREDITS", name: "Créditos de conta" }
]

export const PaymentStatus = [
    { id: "CREATED", name: "Criado" },
    { id: "PENDING", name: "Pagamento pendente" },
    { id: "APPROVED", name: "Aprovado" },
    { id: "REFUSED", name: "Recusado" },
    { id: "CANCELLED", name: "Cancelado" },
    { id: "REFUNDED", name: "Reembolsado" },
    { id: "CHARGEDBACK", name: "Chargeback realizado" }
]

export const PaymentItemType = [
    { id: "RAFFLE_NUMBERS", name: "Números da sorte" },
    { id: "CREDITS", name: "Créditos de conta" }
]