import { NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const UserluckynumberShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
           
            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <NumberField source="number" />

            <ReferenceField source="paymentId" reference="payments" link="show">
                <NumberField source="id" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);