import { Button, CreateButton, DatagridConfigurable, EditButton, FormDataConsumer, List, ReferenceField, ShowButton, TextField, useGetList, useRecordContext, useRedirect } from "react-admin";

export const FederalLottoPrizesGrid = () => {
    const redirect = useRedirect();
    const record = useRecordContext();
                    
    return <div>
        {
            !record ? null : (
                <List
                    resource="federalLottoRafflePrizes"
                    filter={{
                        raffleId: record.id
                    }}
                    exporter={false}
                >
                    <DatagridConfigurable
                        rowClick={(row) => {
                            redirect("show", "federalLottoRafflePrizes", row);
                            return false;
                        }}
                        sort={{
                            field: "id",
                            order: "DESC"
                        }}
                    >
                        <TextField label="Prêmio" source="title" />

                        <ReferenceField
                            label="Número vencedor"
                            source="winningNumberId"
                            reference="userLuckyNumbers"
                        >
                            <TextField source="number" />
                        </ReferenceField>

                        <EditButton />
                        <ShowButton />
                    </DatagridConfigurable>
                </List>
            )
        }
    </div>;
};