import { Button, CreateButton, Datagrid, EditButton, EmailField, FilterButton, List, NumberField, NumberInput, SortButton, TextField, TextInput, TopToolbar } from "react-admin";
import { generateAuthenticatedURL } from "../../../utils/RequestUtils";

const UserFilters = [
    <NumberInput alwaysOn source="id" />,
    <TextInput alwaysOn type="cpf" source="cpf" />,
    <TextInput alwaysOn type="name" source="name" />,
    <TextInput alwaysOn type="email" source="email" />
];

const ExportButton = () => {
    function exportAsCSV() {
        const url: URL = new URL(generateAuthenticatedURL("/users/exporters/base"));
        window.open(url);
    }

    return (
        <Button onClick={exportAsCSV} label="Exportar tudo" />
    )
}

const UserActions = () => (
    <TopToolbar>
        <CreateButton label="Criar usuário" />
        <SortButton fields={["id", "createdAt"]} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

export const UserList = () => (
    <List
        hasCreate={true}
        filters={UserFilters}
        actions={<UserActions/>}
    >
        <Datagrid rowClick="show">
            <NumberField source="id" />
            <EmailField source="email" />
            <TextField source="name" />
            <TextField source="cpf" />
            <TextField source="cep" />
            <TextField source="city" />
            <TextField source="state" />
            <TextField source="tel" />

            <EditButton />
        </Datagrid>
    </List>
);