import { Datagrid, List, TextField } from "react-admin";

export const NotificationList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />

            <TextField source="contents.en" />
            <TextField source="headings.en" />
        </Datagrid>
    </List>
);