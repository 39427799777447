import { Typography } from "@mui/material";
import { Edit, FormDataConsumer, PasswordInput, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, email, required } from "react-admin";
import { AdminRoleList } from "../AdminsResource";

export const AdminEdit = () => (
    <Edit>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Editar administrador</Typography>

            <TextInput fullWidth source="name" />

            <TextInput fullWidth source="email" type="email" validate={[required(), email()]} />

            <PasswordInput fullWidth helperText="Deixe em branco para manter como está." source="password" />

            <SelectInput fullWidth source="role" choices={AdminRoleList} validate={[required()]} />

            <FormDataConsumer>
                {({ formData }) =>
                    formData.role !== "SUPERADMIN" ? (
                        <ReferenceArrayInput reference="raffles" source="raffles">
                            <SelectArrayInput fullWidth optionText="title" />
                        </ReferenceArrayInput>
                    ) : (
                        <Typography color="rgba(255, 255, 255, 0.4)">
                            Super-administradores tem acesso à todos os sorteios.
                        </Typography>
                    )

                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);
