import { useEffect, useState } from "react";
import { Datagrid, FunctionField, NumberField, downloadCSV } from "react-admin";
import { createDataProviderRequest } from "../../../core/Actions";
import { useDashboardContext } from "../Dashboard";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import { unparse as convertToRawCSV } from "papaparse";
import { useQuery } from "react-query";

const exportToCSV = (records) => {
    const rawCSV = convertToRawCSV({
        data: records,
        fields: Object.keys(records?.[0] ?? []),
    }, {
        quotes: true
    });

    downloadCSV(
        rawCSV, 
        // This will be used as the downloaded file name.
        "affiliates"
    ); 
};

const RegionTable = (props: { rows: any[] | null, mainColumn: { source: string; label: string; } }) => {
    return <>
        <div className="flex justify-end">
            {
                !!props.rows?.length &&
                    <Button
                        startIcon={<Download />}
                        onClick={() => exportToCSV(props.rows)}
                    >
                        Exportar CSV
                    </Button>
                
            }
        </div>

        <Datagrid
            data={props.rows?.length ? props.rows : [{ [props.mainColumn.source]: !props.rows ? "Obtendo informações..." : "Sem registros" }]}
            sort={{ field: props.mainColumn.source, order: "ASC" }}
        >
            <FunctionField render={(record) => record[props.mainColumn.source]} label={props.mainColumn.label} sortable={true} sortBy={props.mainColumn.source} sortByOrder="ASC" />

            <NumberField label="Pagamentos criados" source="totalPaymentsCount" />

            <NumberField label="Pagamentos aprovados" source="totalApprovedCount" />

            <NumberField label="Valor total não aprovado" source="totalNonApprovedValue" locales="pt-BR" options={{ style: "currency", currency: "BRL" }} />

            <NumberField label="Valor total aprovado" source="totalApprovedValue" locales="pt-BR" options={{ style: "currency", currency: "BRL" }} />
        </Datagrid>
    </>
}

export const RegionsTab = () => {
    const dashboard = useDashboardContext();

    const [regionsByState, setRegionsByState] = useState(null);
    const [regionsByStateAndCity, setRegionsByStateAndCity] = useState(null);

    const refreshData = () => {
        // Retrieve the reports data for it
        createDataProviderRequest("get", "reports", {
            ...dashboard.queryData,
            groupBy: "state"
        }).then(async (data) => {
            const jsonData = (await data.json());

            setRegionsByState(jsonData);
        });

        // Retrieve the reports data for it
        createDataProviderRequest("get", "reports", {
            ...dashboard.queryData,
            groupBy: "city-state"
        }).then(async (data) => {
            const jsonData = (await data.json());

            setRegionsByStateAndCity(jsonData);
        });
    };

    useEffect(refreshData, [dashboard]);
    useQuery(["dashboardRegions"], refreshData);

    return (
        <div className="space-y-6">
            <RegionTable rows={regionsByState} mainColumn={{
                label: "Estado",
                source: "state"
            }} />

            <RegionTable rows={regionsByStateAndCity} mainColumn={{
                label: "Cidade e estado",
                source: "cityAndState"
            }} />
        </div>
    );
};