import { ReactElement, ReactNode } from "react";
import {
    List,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
    MenuItem
} from "@mui/material";

import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useTranslate, useSidebarState } from "react-admin";

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    children: ReactNode;
}

const SubMenu = (props: Props) => {
    const { handleToggle, isOpen, name, icon, children, dense } = props;
    const translate = useTranslate();

    const [sidebarIsOpen] = useSidebarState();

    const header = (
        <MenuItem
            dense={dense}
            onClick={handleToggle}
            sx={{ justifyContent: "space-between" }}
        >
            <ListItemIcon sx={{ minSize: 5 }}>
                {icon}
            </ListItemIcon>

            <Typography variant="inherit" color="textSecondary" sx={{ flexGrow: 1 }}>
                {translate(name)}
            </Typography>

            <ListItemIcon sx={{ justifyContent: "right" }}>
                {(isOpen ? <ExpandMore /> : <ExpandLess/> as any)}
            </ListItemIcon>
        </MenuItem>
    );

    return (
        <div>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        "& a": {
                            transition:
                                "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    );
};

export default SubMenu;