import { BooleanField, DateField, NumberField, ReferenceField, SelectField, Show, SimpleShowLayout, TextField, useNotify, useRecordContext } from "react-admin";
import { CouponBenefitTypes } from "../Coupons";

const ClickToCopyInput = ({ source }) => {
    const recordCtx = useRecordContext();
    const notify = useNotify();
  
    const copyToClipboard = async (text: string) => {
        navigator.clipboard.writeText(text)
    };

    const handleClick = () => {
        copyToClipboard(recordCtx[source]);

        notify("Copiado para área de transferência");
    };
  
    return (
        <div 
            className={"text-primary"}
            style={{ cursor: "pointer" }}
            onClick={handleClick}
        >
            <TextField source={source} />
        </div>
    );
  };

export const CouponShow = () => (
    <Show>
        <SimpleShowLayout>
            <span className="text-xs opacity-90">Benefício</span>
            <div className="flex flex-col gap-1 md:flex-row">
                <NumberField fullWidth source="benefitAmount" />

                <SelectField fullWidth source="benefitType" choices={CouponBenefitTypes} />
            </div>

            <ClickToCopyInput source="fancyCode" />

            <BooleanField fullWidth source="used" valueLabelFalse="Não resgatado" valueLabelTrue="Resgatado" />

            <ReferenceField fullWidth reference="users" source="usedById" link="show" emptyText="Ninguém">
                <TextField source="name" />
            </ReferenceField>

            <DateField source="expiresAt" showTime={true} />

            <DateField fullWidth source="createdAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
);