import * as XLSX from "xlsx";

/**
 * Export data to an Excel file
 * At the moment is working only with formats xls and xlsx (can be extended to other formats)
 */
export function exportToExcel(data, filename: string, format?: XLSX.BookType) {
    // Create a new excel workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, filename);

    // Write the workbook to a buffer
    const buffer = XLSX.write(wb, { 
        type: "buffer", 
        bookType: format ?? "xlsx" 
    });

    // Create a blob from the buffer
    const blob = new Blob([buffer], { 
        type: format === "xls" 
            ? "application/excel" 
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.${format ?? "xlsx"}`);

    // Simulate a click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link element
    window.URL.revokeObjectURL(url);
}
