import { Typography } from "@mui/material";
import { ReferenceInput, SelectInput, SimpleForm, TextInput, required, Edit } from "react-admin";

export const FederalLottoRafflePrizeEdit = () => (
    <Edit>
        <SimpleForm>
            <Typography variant="h5" mb={3}>Editar prêmio</Typography>

            <TextInput fullWidth source="title" validate={[required()]} />

            <ReferenceInput fullWidth source="raffleId" reference="federalLottoRaffles" validate={[required()]}>
                <SelectInput optionText="title" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);