import { Typography } from "@mui/material";
import { AppBar, ToggleThemeButton, defaultTheme } from "react-admin";
import { CustomUserMenu } from "./parts/UserMenu";
import { Configuration } from "../config";

export const AdminAppBar = (props) => (
    <AppBar
        {...props}
        userMenu={<CustomUserMenu />}
    >
        <img className="h-auto w-[130px] mr-3 sm:h-[32px] sm:w-auto" src={Configuration.logo} />

        <Typography flex="1" variant="h6" id="react-admin-title"></Typography>

        <ToggleThemeButton
            lightTheme={defaultTheme}
            darkTheme={{
                palette: {
                    mode: "dark"
                }
            }}
        />
    </AppBar>
)