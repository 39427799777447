import { AutocompleteArrayInput, Datagrid, FilterButton, List, Pagination, ReferenceArrayInput, ReferenceField, TextField, TopToolbar, CreateButton, CloneButton, SelectField, BooleanField } from "react-admin";
import { RafflePrizeKind } from "../../Raffles";

const ListPagination = () => <Pagination perPage={10} rowsPerPageOptions={[5, 10, 30, 50, 100]} />;

const RaffleLuckyNumberFilter = [
    <ReferenceArrayInput alwaysOn source="raffleId" reference="raffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="resources.userLuckyNumbers.filters.raffleTitle" />
    </ReferenceArrayInput>
];

const RafflePrizesActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);

export const RafflePrizeList = () => (
    <List
        filters={RaffleLuckyNumberFilter}
        actions={<RafflePrizesActions />}
        pagination={<ListPagination/>}
        perPage={10}
    >
        <Datagrid rowClick="show">
            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <TextField source="name" />

            <TextField source="siteName" />

            <SelectField source="raffleKind" choices={RafflePrizeKind} />

            <BooleanField source="unlisted" />

            <CloneButton />
        </Datagrid>
    </List>
);