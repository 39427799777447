import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import { ConsumerCreate } from "./partners/PartnerCreate";
import { ConsumerEdit } from "./partners/PartnerEdit";
import { PartnerList } from "./partners/PartnerList";
import { ConsumerShow } from "./partners/PartnerShow";

export const ConsumersResource = {
    list: PartnerList,
    edit: ConsumerEdit,
    show: ConsumerShow,
    create: ConsumerCreate,
    icon: SocialDistanceIcon
}
