import { ArrayField, Button, Datagrid, DateField, EditButton, FormDataConsumer, FormTab, FunctionField, NumberField, ReferenceField, SelectField, Show, SimpleShowLayout, TabbedForm, TextField, TopToolbar, useGetList, useNotify, useRecordContext, useShowContext, useUpdate } from "react-admin";
import { PaymentItemType, PaymentMethods, PaymentStatus } from "../Payments";
import { SendSecondWayButton } from "../../shared/SendSecondWayButton";
import { ReapprovePaymentButton } from "../../shared/ReapprovePaymentButton";
import { Configuration } from "../../../config";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ApprovePaymentButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate("payments");
    const notify = useNotify();

    const doApprove = async () => {
        await update("payments", {
            id: record?.id,
            data: {
                status: "APPROVED"
            }
        });

        notify("Pagamento aprovado.");
    };

    return (
        <Button
            color={"success" as any}
            label={"Aprovar #" + record?.id}
            onClick={doApprove}
        />
    )
};

export const PaymentShowLayout = () => {
    const { isLoading, record } = useShowContext();

    const [currentPage, setCurrentPage] = useState(1);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    
    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
    

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    return (
        <TabbedForm>
            <FormTab label="Dados do pagamento">
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="uuid" />
                    <DateField showTime source="createdAt" />
        
                    <ReferenceField source="userId" reference="users">
                        <TextField source="name" />
                    </ReferenceField>
        
                    <SelectField source="method" choices={PaymentMethods} />
        
                    <SelectField source="status" choices={PaymentStatus} />
        
                    <NumberField source="value" options={{ style: "currency", currency: "BRL" }} />
        
                    <ReferenceField source="affiliateId" reference="affiliates" emptyText="Não houve">
                        <TextField source="name" />
                    </ReferenceField>
        
                    <ArrayField source="items">
                        <Datagrid
                            // Removes bulk actions (like select/delete)
                            bulkActionButtons={false}
                        >
                            <SelectField label="Tipo do item" source="type" choices={PaymentItemType} />
        
                            <NumberField label="Quantidade" source="amount" />
        
                            <ReferenceField label="Sorteio" source="raffleId" reference="raffles" emptyText="Não relacionado a um sorteio">
                                <TextField source="title" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
        
                    {
                        !!record?.data?.mercadoPagoId && (
                            <TextField source="data.mercadoPagoId" label="ID de pagamento de MercadoPago" />
                        )
                    }
                    
                    {
                        !!record?.data?.authorizationId && (
                            <TextField source="data.authorizationId" label="ID de autorização do PicPay" />
                        )
                    }
        
                    <ArrayField source="data.logs">
                        <Datagrid
                            // Disables bulk select/delete
                            bulkActionButtons={false}
                        >
                            <DateField showTime source="date" />
                            <SelectField source="status" choices={PaymentStatus} />
                            <TextField source="message" />
                        </Datagrid>
                    </ArrayField>
                </SimpleShowLayout>
            </FormTab>

            <FormTab label="Números adquiridos">
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => {
                            const { data, total, isLoading } = useGetList("userLuckyNumbers", {
                                filter: {
                                    paymentId: formData.id
                                },
                                pagination: {
                                    page: currentPage,
                                    perPage: 100
                                }
                            });

                            const totalPages = Math.ceil((total || 0) / 100);

                            return (
                                <>
                                    {!isLoading && (
                                        <Datagrid data={data} sort={{
                                            field: "id",
                                            order: "DESC"
                                        }}>
                                            <FunctionField label="Número" source="number" render={(value) => String(value.number).padStart(Configuration.settings?.bondDigits || 0, "0")} />
                                        </Datagrid>
                                    )}

                                    <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                                        {(data && data.length === 100) && (
                                            <Button 
                                                onClick={handlePrevPage} 
                                                disabled={currentPage === 1}
                                            >
                                                <ArrowBackIosIcon />
                                            </Button>
                                        )}

                                        {(data && data.length === 100) && (
                                            <span style={{ marginLeft: '10px', marginRight: '10px'}}>
                                                Página {currentPage} de {totalPages}
                                            </span>
                                        )}
                                        
                                        {(data && data.length === 100) && (
                                            <Button 
                                                onClick={handleNextPage} 
                                                disabled={data ? data.length < 100 : true}
                                            >
                                                <ArrowForwardIosIcon />
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )
                        }
                    }
                </FormDataConsumer>
            </FormTab>
        </TabbedForm>
    );
}

const PaymentActions = () => {
    const record = useRecordContext();

    return (
        <TopToolbar>
            { record?.status !== "APPROVED" && <ApprovePaymentButton /> }
            { record?.status === "APPROVED" && <SendSecondWayButton /> }
            { record?.status === "APPROVED" && <ReapprovePaymentButton /> }
            
            <EditButton />
        </TopToolbar>
    );
}

export const PaymentShow = () => {
    return (
        <Show
            actions={
                <PaymentActions />
            }
        >
            <PaymentShowLayout></PaymentShowLayout>
        </Show>
    );
}
