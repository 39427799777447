type OmitValue<
    T extends Record<any, any> | any[],
    R extends any
> = {
    [key in keyof T]: T[key] extends Record<any, any> | any[] ? OmitValue<T[key], R> : (
        T[key] extends R ? never : T[key]
    );
}

/**
 * Removes all undefined properties from an object.
 * @prop obj The object to remove all `undefined`.
 * @returns
 */
export function removePickedValues<
    T extends Record<string, any>,
    R extends any[]
>(obj: T, toRemove: R): OmitValue<T, R> {
    // Clone the final object
    const final = { ...obj };

    for (const key in final) {
        if (typeof final[key] === "object") {
            removePickedValues(final[key], toRemove);
            continue;
        }

        // Remove the value if needed
        if (toRemove.includes(final[key])) {
            delete final[key];
        }
    }

    return final;
}

/**
 * Removes all undefined properties from an object.
 * @prop obj The object to remove all `undefined`.
 * @returns
 */
export function removeUndefined<T extends Record<string, any>>(obj: T): OmitValue<T, undefined> {
    return removePickedValues(obj, [undefined]);
}

/**
 * Removes all null properties from an object.
 * @prop obj The object to remove all `null`.
 * @returns
 */
export function removeNull<T extends Record<string, any>>(obj: T): OmitValue<T, null> {
    return removePickedValues(obj, [null]);
}

/**
 * Removes all null or undefined properties from an object.
 * @prop obj The object to remove all `undefined` or `null`.
 * @returns
 */
export function removeNullOrUndefined<T extends Record<string, any>>(obj: T): OmitValue<T, undefined | null> {
    return removePickedValues(obj, [undefined, null]);
}