import { Edit, CloneButton, TopToolbar, ShowButton } from "react-admin";
import { RafflePrizeForm } from "./RafflePrizeForm";

export const RafflePrizeEdit = () => (
    <Edit
        actions={
            <TopToolbar>
                <CloneButton />
                <ShowButton />
            </TopToolbar>
        }
    >
        <RafflePrizeForm kind="EDIT" />
    </Edit>
);