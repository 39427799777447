import { AutocompleteArrayInput, Datagrid, FilterButton, List, Pagination, ReferenceArrayInput, ReferenceField, TextField, TopToolbar, CreateButton, NumberField } from "react-admin";

const ListPagination = () => <Pagination perPage={10} rowsPerPageOptions={[5, 10, 30, 50, 100]} />;

const RaffleLuckyNumberFilter = [
    <ReferenceArrayInput alwaysOn source="raffleId" reference="federalLottoRaffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="Filtrar por sorteio" />
    </ReferenceArrayInput>
];

const RafflePrizesActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);

export const FederalLottoRafflePrizeList = () => (
    <List
        filters={RaffleLuckyNumberFilter}
        actions={<RafflePrizesActions />}
        pagination={<ListPagination/>}
        perPage={10}
    >
        <Datagrid rowClick="show">
            <ReferenceField source="raffleId" reference="federalLottoRaffles">
                <TextField source="title" />
            </ReferenceField>

            <TextField source="title" />

            <ReferenceField
                link="show"
                source="winningNumberId"
                reference="userLuckyNumbers"
            >
                <NumberField source="number" />
            </ReferenceField>
        </Datagrid>
    </List>
);