export const FinancialsStatus = [
    { name: "Pendente", id: "PENDING" },
    { name: "Em análise", id: "ANALYSIS" },
    { name: "Pago", id: "PAID" }
];

export const FinancialOrigin = [
    { name: "Sorteio diário", id: "DAILY_RAFFLE" },
    { name: "Sorteio", id: "RAFFLE" }
];

export const FinancialOriginSubtype = [
    { name: "Sorteio diário", id: "DAILY_RAFFLE" },
    { name: "Hora do viva", id: "SECONDARY" },
    { name: "Sorteio principal", id: "MAIN" }
];