import { createContext, useContext } from "react";

export const ResultGeneratorConfigContext = createContext<{
    /**
     * The pattern background URL.
     */
    patternBackgroundFile?: string;

    /**
     * The page title.
     */
    title?: string | null;

    /**
     * The page subtitle.
     */
    subtitle?: string | null;

    /**
     * The raffle date.
     */
    date?: string | null;

    /**
     * The colors for the generator.
     */
    colors?: {
        primary?: string;
        secondary?: string;
        tertiary?: string;
    };

    /**
     * How much winners to display per image.
     */
    winnersPerImage: number;

    /**
     * If can group prize results by title.
     */
    groupByTitle: boolean;

    /**
     * The identifier for the selected raffle.
     * It can be `ALL` for all raffles in the day, or `{TYPE}:{ID/UUID}`.
     */
    raffleIdentifier: "ALL" | string;
}>({

} as any);

export const useResultGeneratorConfig = () => useContext(ResultGeneratorConfigContext);