import { BooleanField, DateField, NumberField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";

const QueryList = () => {
    const record = useRecordContext();
    return (
        <div className="flex flex-wrap gap-3">
            {record.stats?.executedQueries?.map(item => (
                <div key={item} className="w-full p-3 font-mono text-sm border rounded-md">
                    { item.replace(/\/\*.+?\*\//, "") }
                </div>
            ))}
        </div>
    )
};

export const DatabaseShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="user" />
                <NumberField source="time" />
                <DateField source="state" />
                <TextField source="command" />

                <BooleanField source="stats.inQuery" defaultChecked={false} />
                <BooleanField source="stats.inTransaction" defaultChecked={false} />

                <QueryList />
            </SimpleShowLayout>
        </Show>
    )
};