import { BooleanField, Datagrid, DateField, FunctionField, List, NumberField, SelectField, TextField } from "react-admin";
import { CouponBenefitTypes } from "../Coupons";

export const CouponList = () => (
    <List
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <FunctionField label="Código" render={(record: { fancyCode: string }) => record.fancyCode.substring(0, 9) + "-****-****-****"} />
            <NumberField source="benefitAmount" />
            <SelectField source="benefitType" choices={CouponBenefitTypes} />
            <BooleanField source="used" valueLabelFalse="Não resgatado" valueLabelTrue="Resgatado" />
            <DateField source="expiresAt" showTime={true} />
            <DateField source="createdAt" showTime={true} />
        </Datagrid>
    </List>
);
