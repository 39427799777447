import { BooleanField, Datagrid, List, TextField, ImageField, SelectField } from "react-admin";
import { ResellerMaterialCategory } from "../../Affiliates";

export const ResellersMaterialList = () => (
    <List
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <Datagrid rowClick="show">
            <ImageField source="url" />
            <TextField source="title" />
            <BooleanField source="active" />

            <SelectField source="category" choices={ResellerMaterialCategory} />
        </Datagrid>
    </List>
);