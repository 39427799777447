import Leaderboard from "@mui/icons-material/Leaderboard";
import MilitaryTech from "@mui/icons-material/MilitaryTech";
import { FederalLottoRaffleShow } from "./federal-lotto-raffles/FederalLottoRaffleShow";
import { FederalLottoRaffleEdit } from "./federal-lotto-raffles/FederalLottoRaffleEdit";
import { FederalLottoRaffleList } from "./federal-lotto-raffles/FederalLottoRaffleList";
import { FederalLottoRaffleCreate } from "./federal-lotto-raffles/FederalLottoRaffleCreate";
import { FederalLottoRafflePrizeCreate } from "./federal-lotto-raffles/prizes/FederalLottoRafflePrizeCreate";
import { FederalLottoRafflePrizeEdit } from "./federal-lotto-raffles/prizes/FederalLottoRafflePrizeEdit";
import { FederalLottoRafflePrizeList } from "./federal-lotto-raffles/prizes/FederalLottoRafflePrizeList";
import { FederalLottoRafflePrizeShow } from "./federal-lotto-raffles/prizes/FederalLottoRafflePrizeShow";

export const FederalLottoRafflesResource = {
    list: FederalLottoRaffleList,
    edit: FederalLottoRaffleEdit,
    icon: Leaderboard,
    show: FederalLottoRaffleShow,
    create: FederalLottoRaffleCreate
}

export const FederalLottoRafflePrizesAndWinnersResource = {
    create: FederalLottoRafflePrizeCreate,
    edit: FederalLottoRafflePrizeEdit,
    list: FederalLottoRafflePrizeList,
    show: FederalLottoRafflePrizeShow,
    icon: MilitaryTech
}