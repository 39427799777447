import { Datagrid, EmailField, List, NumberInput, TextField, TextInput } from "react-admin";

const AmbassadorsFilters = [
    <NumberInput alwaysOn source="id" />,
    <TextInput alwaysOn type="name" source="name" />,
    <TextInput alwaysOn type="email" source="email" />
];

export const AmbassadorsList = () => (
    <List 
        hasCreate
        filters={AmbassadorsFilters} 
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
        </Datagrid>
    </List>
);