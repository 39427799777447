import { NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";

export const RaffleLuckyNumberShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />

            <ReferenceField source="raffleId" reference="raffles">
                <TextField source="title" />
            </ReferenceField>

            <NumberField source="number" />

            <ReferenceField source="reservationPaymentId" reference="reservationPayments">
                <NumberField source="id" />
            </ReferenceField>

        </SimpleShowLayout>
    </Show>
);